// @ts-ignore
import { Json } from "@types/common";

export const assetsTab = [
  "Watchlist",
  "Horses",
  "Private Stock",
  "Crypto",
  // "music",
  "sba7",
  "Real Estate",
  "Manufactured Home Loans",
  "Equipment Finance",
  // "stocks",
  // "forex",
  // "commodities",
  // "marketIndexes",
];

export const searchTab = [
  "All",
  "Name",
  "Symbol",
  "Issuer",
];

export const SBA7A = "SBA(7a)";

export const assetTabs: any = {
  WATCHLIST: "Watchlist",
  HORSES: "Horses",
  STOCKS: "stocks",
  REALESTATE: "Real Estate",
  MUSIC: "music",
  CRYPTO: "Crypto",
  PRIVATES: "Private Stock",
  COMMODITIES: "commodities",
  FOREX: "forex",
  MARKETINDEXES: "marketIndexes",
  SBA7: "sba7",
  MHL: "Manufactured Home Loans",
  EF: "Equipment Finance",
};

export const setterMapping: any = {
  Watchlist: "setWatchList",
  stocks: "setStocks",
  realEstate: "setrealEstate",
  Horses: "setHorses",
  sba7: "setSba",
  // music: "setMusics",
  forex: "setForex",
  privates: "setPrivatesState",
  commodity: "setCommoditiesState",
};
export const assetTabsInclude: any = {
  WATCHLIST_QUERY: "watchlist",
  STOCKS_QUERY: "stocks",
  HORSES_QUERY: "horse_trade",
  REALESTATE_QUERY: "real-estate",
  // MUSIC_QUERY: "music",
  CRYPTO_QUERY: "crypto",
  PRIVATES_QUERY: "privates",
  COMMODITIES_QUERY: "commodities",
  FOREX_QUERY: "forex",
  MARKETINDEXES_QUERY: "marketIndexes",
  SBA7_QUERY: "sba7",
};

export const navigation = ["Chart", "Order books", "Trades"];

export const colorList = {
  flickerGreen: "#58BD7D",
  flickerRed: "#FF6838",
  flickerWhite: "#ffffff",
  flickerBlack: "#17181b",
};

export const assestType: { [key: string]: string } = {
  stocks: "stock",
  realEstate: "real_estate",
  Horses: "horse_trade",
  crypto: "crypto",
  commodities: "commodity",
  privates: "privates",
  forex: "forex",
  marketIndexes: "marketIndex",
  // music: "music",
  SBA7: "sba7",
  MHL: "manufactured_home_loans",
  EF: "equipment_finance"
};
export const actions = { BUY: "buy", SELL: "sell" };

export const PROVIDER = { LQDTY: "liquidity" };

export const defaultActiveCurrency = {
  id: "",
  name: "",
  symbol: "",
  marketPrice: 0,
  change: 0,
  changesPercentage: 0,
  image: "",
  imageFrom: "",
  imageTo: "",
  perDay: [],
  type: "Watchlist",
  address: "",
  lender_loan_number: "",
  isuerDetails: {}
};

export const LOAN_ASSETS: any = {
  manufactured_home_loans: true,
  equipment_finance: true,
  sba7: true
}

export const ACTIVE_ASSET_TAB_WITH_SPACE: any = {
  privates: "Private Stock",
  real_estate: "Real Estate",
  manufactured_home_loans: "Manufactured Home Loans",
  equipment_finance: "Equipment Finance",
  horse_trade: "Horses",
  crypto: "Crypto",
  Watchlist: "Watchlist",
}

export const ASSET_KEYS_BY_NAME: any = {
  "Private Stock": "privates",
  "Real Estate": "real_estate",
  "Manufactured Home Loans": "manufactured_home_loans",
  "Equipment Finance": "equipment_finance",
  "Horses": "horse_trade",
  "Crypto": "crypto",
  "Mutual Fund": "mutual_funds",
  "Watchlist": "Watchlist",
}

export const HIDE_ISSUER_NAME: Record<string, boolean> = {
  crypto: true
}

export const ASSETS_CONFIG: Json = {
  privates: { decimal: 2, priceRegex: /^(?!0{2,})[0-9]{0,10}(?:[.][0-9]{0,2})?$/, qtyRegex: /^[0-9]{0,8}$/ },
  horse_trade: { decimal: 2, priceRegex: /^(?!0{2,})[0-9]{0,10}(?:[.][0-9]{0,2})?$/, qtyRegex: /^[0-9]{0,8}$/ },
  real_estate: { decimal: 2, priceRegex: /^(?!0{2,})[0-9]{0,10}(?:[.][0-9]{0,2})?$/, qtyRegex: /^[0-9]{0,8}$/ },
  manufactured_home_loans: {
    decimal: 6,
    priceRegex: /^(?!0{2,})[0-9]{0,10}(?:[.][0-9]{0,6})?$/,
    qtyRegex: /^[0-9]{0,8}$/
  },
  equipment_finance: { decimal: 6, priceRegex: /^(?!0{2,})[0-9]{0,10}(?:[.][0-9]{0,6})?$/, qtyRegex: /^[0-9]{0,8}$/ },
  crypto: {
    decimal: 8,
    qtyDecimal: 8,
    priceRegex: /^(?!0{2,})[0-9]{0,10}(?:[.][0-9]{0,8})?$/,
    qtyRegex: /^(?!0{2,})[0-9]{0,15}(?:[.][0-9]{0,8})?$/
  },
  sba7: { decimal: 6, priceRegex: /^(?!0{2,})[0-9]{0,10}(?:[.][0-9]{0,6})?$/, qtyRegex: /^[0-9]{0,8}$/ },
}

export const ORDERBOOK_SERIES = "ORDERBOOK_SERIES";
export const gov_guaranteed_loan = "current_guaranteed_loan_balance";

export const MORNING_LINE = "Morning Line";
export const LIQUIDITY = "Liquidity";
