import React, { useEffect } from "react";
import useMpcWalletApi from "./store/useMpcWalletApi";

const MpcWallet = () => {
  const { blockchainShard, getSignHash } = useMpcWalletApi();

  useEffect(() => {
    window.addEventListener("message", function (event: any) {
      if (event.data) {
        const details = JSON.parse(event.data);
        if (details?.type === "signature-key") {
          getSignHash(details?.shards, details?.payload).then((res) => {
            console.log("signnature", res?.signature);
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ signature: res?.signature, type: details?.type })
            );
          });
        }
      }
    });
    blockchainShard();
  }, []);

  return <div></div>;
};

export default MpcWallet;
