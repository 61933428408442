import { useNetwork } from "@hooks/network";
import { downloadGoogleapisFile } from "@utils/common";
import { APIS } from "constant";
import React, { useCallback, useState } from "react";

const useMyDocumentApi = () => {
  const { get: getMonthlyStatement, loading: monthlyStatementLoading } =
    useNetwork();
  const [loadingDownload, setLoadingDownload] = useState<string | null>(null);
  const [loadingView, setLoadingView] = useState<string | null>(null);

  interface GetMyDocumentParams {
    endDate: string;
    startDate: string;
    download?: boolean;
    loadingDownload?: string;
    loadingView?: string;
  }

  const getMyDocument = useCallback(
    async ({
      startDate,
      endDate,
      download = false,
      loadingDownload,
      loadingView,
    }: GetMyDocumentParams) => {
      download && setLoadingDownload(loadingDownload || null);
      !download && setLoadingView(loadingView || null);
      try {
        const res = await getMonthlyStatement(
          `${APIS.MONTHLY_STATEMENTS}?to=${endDate}&from=${startDate}`
        );

        if (res?.data?.file_location) {
          const fileUrl = res.data.file_location;

          if (download) {
            downloadGoogleapisFile(fileUrl, fileUrl.split("/").pop() || "document.pdf");
          } else {
            // Open file in a new tab
            window.open(fileUrl, "_blank");
            setLoadingView(null);
          }
        } else {
          console.error("File location not found in response");
        }
      } catch (error) {
        console.error("Error fetching document:", error);
      } finally {
        setLoadingDownload(null);
        setLoadingView(null);
      }
    },
    []
  );
  return {
    getMyDocument,
    monthlyStatementLoading,
    loadingDownload,
    loadingView,
  };
};

export default useMyDocumentApi;
