import React, { useState, useCallback, useEffect, useMemo } from "react";
import cn from "classnames";
import { useNavigate } from "react-router-dom";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { useNetwork, useNotification, usePrevious, useWehAuthn } from "hooks";
import {
  LoginPhoneNumberState,
  LoginUserState,
  SendOTPState,
  userPersonalDetails,
} from "states";
import { CountdownTimer, Loader, LoaderLine } from "components";
import { useCookie } from "hooks/cookie";
import { APIS, REGEX_ACCEPT_ONLY_NUMBER, TRACKING_EVENTS, loginToasterMessages } from "constant";
import { AuthTypeState } from "views/SignIn/stores";
import { ROUTES } from "routes";
import styles from "./Form.module.sass";
// @ts-ignore
import { Json } from "@types/common";
import { addSeconds } from "date-fns";
import axios from "axios";
import { REACT_APP_API_HOST as API_HOST } from "envs";
import { signInWithTokenAndSetPersistence, trackEventGA, trackEventSG } from "libs";
import { useSwitchWorkSpace } from "@views/SignIn/hooks/useSwitchWorkSpace";
import { formatPhoneNumber } from "@utils/format-number";
import classNames from "classnames";
import { Image } from "../../../../@storybook";
import { imageConfig } from "@utils/imageConfig";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import { MORNING_LINE } from "@views/exchange/constants";

const { EXCHANGE ,NO_ROUTE} = ROUTES;
const { LOGIN_SUCCESS } = loginToasterMessages;

export interface IOtpObject {
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
}

export const OtpVerify = () => {
  const userDetails = useRecoilValue(userPersonalDetails);
  const setLoginUser = useSetRecoilState(LoginUserState);
  const loginPhoneNumber = useRecoilValue(LoginPhoneNumberState);
  const AuthType = useRecoilValue(AuthTypeState);
  const setShowOtpPage = useSetRecoilState(SendOTPState);
  const { post: sendOtp, loading: sendOtpLoading, data: sendOptData } = useNetwork();
  const prevSendOtpLoading = usePrevious(sendOtpLoading);
  const { getWebAuthnSupported } = useWehAuthn();

  const [otpValue, setOtpValue] = useState<IOtpObject>({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
  });
  const [otpCount, setOtpCount] = useState<number>(1);
  const [focusEvent, setFocusEvent] = useState<any>({});
  const [phoneNumber] = useState<string>(loginPhoneNumber.phone);
  const [wrongOtpState, setWrongOtpState] = useState(false)
  const [countryCode] = useState<string | number>(loginPhoneNumber.countryCode);
  const [sendCodeTimer, setSendCodeTimer] = useState<any>(null);
  const { fetchWorkSpaceUser, workSpaceUsersLoading } = useSwitchWorkSpace();

  const { NEWUSEROTPVERIFY, LOGIN, SWITCH_ACCOUNT } = ROUTES
  const {
    post: verifyPhone,
    data: verifyOTP,
    loading: verifyOtpLoading,
  } = useNetwork();
  const { successNotification, errorNotification } = useNotification();
  
  const  API = typeof API_HOST !== "undefined" && API_HOST 

  const { INVALID_OTP, ACCEPTED_NUMBER_ONLY } = loginToasterMessages;
  const { set: setCookieToken } = useCookie();
  const navigate = useNavigate();
  const {logo: {LiquidityLogo}} = imageConfig;
  const { config } = usePermissionContext();
  const isMLC = config?.name === MORNING_LINE;
  const goBackLoginPage = useCallback(() => {
    setShowOtpPage(false);
    navigate(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmitOtp = useCallback(() => {
    const eventData = { timestamp: new Date() };
    trackEventSG("enter_otp_web", eventData, userDetails?.email);

    const { otp1, otp2, otp3, otp4 } = otpValue;
    const sentOtp = otp1 + otp2 + otp3 + otp4;
    const payload: any = {
      phone: phoneNumber,
      otp: sentOtp,
      countryCode,
      type: "mobile"
    };
    // if (AuthType === "signup") payload["type"] = AuthType;
    verifyPhone(`${APIS.VerifyPhones}`, payload);
  }, [otpValue, verifyPhone, phoneNumber, countryCode]);

  async function fetchDataWithToken(token: string) {
    try {
      const response = await axios.get(API + APIS.FIREBASE, {
        headers: {
          Authorization: `Bearer ${token}`, // Set Bearer token in Authorization header
        },
      });

      // Log or handle the response data
      if (response.data) {signInWithTokenAndSetPersistence(response.data.data.token);}
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  useEffect(() => {
    (async () => {
      if (verifyOTP) {
        setOtpCount((prev) => prev + 1);
        const { token } = verifyOTP;
        const loginDetails = {
          token: token,
          phone: phoneNumber,
          isLoggedIn: true,
        };
        if (AuthType === "signup" && getWebAuthnSupported()) {
          setLoginUser(loginDetails);
          const { success } = verifyOTP;
          if (success) {
            trackEventGA(TRACKING_EVENTS.PHONE_SIGNUP, {}, userDetails?.email);
            trackEventSG(TRACKING_EVENTS.PHONE_SIGNUP, {}, userDetails?.email);
            navigate(NEWUSEROTPVERIFY);
          } else {
            const eventData = { timestamp: new Date(), attempt_count: otpCount };
            trackEventSG("wrong_otp_web", eventData, userDetails?.email);
            setWrongOtpState(true)
            errorNotification(verifyOTP.message);
            setOtpValue({ otp1: "", otp2: "", otp3: "", otp4: "" });
            focusEvent?.target?.form?.elements[0]?.focus();
          }
        } else {
          if (token) {
            setCookieToken("userDetails", loginDetails);
            const res = await fetchWorkSpaceUser();
            if (res?.message !== "ok") return;
            if (res?.data?.length > 1) {
              navigate(SWITCH_ACCOUNT, { state: { token, response: res } });
              return;
            }

            successNotification(LOGIN_SUCCESS);
            fetchDataWithToken(token);
            navigate(NO_ROUTE);
            setLoginUser((prevState) => {
              const newState = {
                ...prevState,
                isLoggedIn: true,
                token,
              };
              return newState;
            });
          } else {
            const eventData = { timestamp: new Date(), attempt_count: otpCount };
            trackEventSG("wrong_otp_web", eventData, userDetails?.email);
            setWrongOtpState(true)
          }
        }
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumber, verifyOTP, AuthType]);

  const onHandleOtp = useCallback((name: string, e: any) => {
    e.preventDefault();
    const { value } = e.target;
    setWrongOtpState(false)
    if (value.length > 1) return;
    if (REGEX_ACCEPT_ONLY_NUMBER.test(e.target.value)) {
      setOtpValue((prev: IOtpObject) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setOtpValue((prev: IOtpObject) => ({
        ...prev,
        [name]: "",
      }));
    }
  }, []);

  const handleBack = useCallback(() => {
    navigate(-1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleResendOTP = useCallback(() => {
    if (sendOtpLoading) return
    const { countryCode, phone } = loginPhoneNumber;
    sendOtp(APIS.PhoneCodes, { countryCode, phone }, { apiResponse: true });
  }, [sendOtpLoading]);

  useEffect(() => {
    if (sendOptData?.message !== "ok" && !sendOtpLoading && prevSendOtpLoading) {
      errorNotification(sendOptData?.message);
    }

    if (sendOptData?.message === "ok" && !sendOtpLoading && prevSendOtpLoading) {
      setSendCodeTimer(addSeconds(new Date(), 30));
      successNotification(`OTP resent successfully to ${countryCode}${formatPhoneNumber(countryCode + phoneNumber)}`);
    }

  }, [sendOptData])

  const inputFocus = useCallback(
    (event: any) => {
      if (REGEX_ACCEPT_ONLY_NUMBER.test(event.target.value)) {
        setFocusEvent(event);
        const next = event.target.tabIndex;
        if (next < 4) {
          event.target.form.elements[next].focus();
        }
      } else if (event.key === "Delete" || event.key === "Backspace") {
        const next = event.target.tabIndex - 2;
        if (next > -1) {
          event.target.form.elements[next].focus();
        }
      }
    },
    [ACCEPTED_NUMBER_ONLY]
  );

  const otpInput = useMemo(() => {
    return Object.entries(otpValue).map(([key, value], index) => (
      <input
        type="text"
        key={index}
        className={classNames(styles.otpInput, { [styles.inputWrongOtp]: wrongOtpState && Object.values(otpValue).every((v) => v) })}
        name={key}
        value={value}
        onChange={(e) => onHandleOtp(key, e)}
        onKeyUp={(e) => inputFocus(e)}
        maxLength={1}
        minLength={1}
        tabIndex={index + 1}
        onFocus={(e) => e.target.select}
        autoFocus={index === 0}
        placeholder="0"
        autoComplete="off"
        aria-autocomplete="none"
      />
    ));
  }, [inputFocus, onHandleOtp, otpValue, wrongOtpState]);

  const { otp1, otp2, otp3, otp4 } = otpValue;
  const submitBtn = !(otp1 && otp2 && otp3 && otp4);

  useEffect(() => {
    if (!submitBtn) {
      handleSubmitOtp()
    }
  }, [submitBtn]);

  const renderTimer = useCallback(({ minutes, seconds, completed }: Json) => {
    if (completed) setSendCodeTimer(null);
    return `${(seconds < 10 ? "0" : "") + seconds}`;
  }, []);

  return (
    // otp verify screen
    <form className={isMLC ? styles.mlc_form : styles.form}>
      <Image
        className={`${styles.brand_logo} `} url={config?.name !== "Liquidity" ? config?.branding?.logo?.dark : LiquidityLogo} />

      <div>
        <span className={styles.back} onClick={() => navigate(-1)}><i className={classNames("ri-arrow-left-line", styles.backIcon)} />Back</span>
        <div className={styles.otp}>
          <div className={styles.otp_detail}>One time passcode sent to your phone</div>
          <div className={styles.otp_detail__text}>
            Please enter the One-Time Passcode (OTP) sent to your phone number  (<span className={styles.signInPhoneNumber}>
              {`${countryCode} ${!loginPhoneNumber.phone ? navigate(LOGIN) : loginPhoneNumber.phone} `}
            </span>) below to authenticate. <br />

          </div>
          <div className={styles.container}>
            <div className={styles.line}>{otpInput}</div>
          </div>
          {wrongOtpState && Object.values(otpValue).every((v) => v) && <div className={styles.wrongOtpError}><span> <i className={`ri-error-warning-fill ${styles.optErrorIcon}`} /></span>The One-Time Passcode (OTP) you entered is incorrect. Please try again or request a new OTP.</div>}
          <div className={styles.verify_button}>
            <button
              className={cn("buttons", styles.buttons)}
              type="button"
              onClick={handleSubmitOtp}
              disabled={submitBtn || verifyOtpLoading || workSpaceUsersLoading}
            >
              {verifyOtpLoading || workSpaceUsersLoading ? <Loader dimension={22} className="loader-white" /> : "Authenticate"}
            </button>
          </div>
          {/* {AuthType === "login" && (
            <div className={styles.verify_button}>
              <button
                className={cn("buttons", styles.back_button)}
                type="button"
                style={{ backgroundColor: "#999" }}
                onClick={handleBack}
              >
                Back
              </button>
            </div>
          )} */}
          <div className={styles.resend}>
            {!sendCodeTimer && <>
              <span className={styles.resendText}>Didn't receive the passcode? </span>
              <span className={styles.editBtn} onClick={handleResendOTP}>
                {sendOtpLoading ?
                  <Loader dimension={18} className="loader-white" /> : "Resend"}
              </span>
            </>}

            {!!sendCodeTimer && <>
              <span className={styles.resendText}>{`Didn't receive the passcode?`}</span>
              <span className={styles.resendText}>Resend in</span>
              <div className="style.send_timer">
              <span className={styles.sendCodeTimer}>
                00
              </span>
              <span className={styles.sendCodeTimerDivide}>
                :
              </span>
              <span className={styles.sendCodeTimer}>
                <CountdownTimer
                  dateTime={sendCodeTimer}
                  renderer={(value) => renderTimer(value)}
                />
              </span>
              </div>
            </>}
          </div>
        </div>
      </div>
    </form>
  );
};
export default OtpVerify
