import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useRecoilState, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import cn from "classnames";
import useDarkMode from "use-dark-mode";

import { SkeletonWatchList } from "components";
import {
  ActiveCurrencyState,
  ActiveAssetTab,
  StockState,
  WatchlistState,
  ForexState,
  PrivatesState,
  CommoditiesState,
  MusicState,
  SbaState, ManuHomeLoanState,
  initialQueryMHLoanState,
  initialQuerySbaState,
  initialQueryPrivateState,
  initialQueryEFLoanState,
  EquipmentFinanceLoanState,
  RealEstateState, CryptoState, initialQueryCryptoState,
  HorsesState,
  initialQueryHorsesState, firebaseIsFeatureEnableState,
  SecondaryMortgagesState,
  ActiveCurrencyStateLoader, showCurrentOverlayInMobileState,
} from "states";
import { useNetwork, useIndexedDB, INIT_CONFIGURATION_SAVE, useCookie } from "hooks";
import { APIS } from "constant";
import { useNotification } from "@hooks/notification";

import { Assets } from "../assets";
import {
  assestType,
  ACTIVE_ASSET_TAB_WITH_SPACE,
  assetsTab,
  assetTabs,
  assetTabsInclude,
  defaultActiveCurrency,
  SBA7A, ASSET_KEYS_BY_NAME,
  MORNING_LINE, SM_DETAIL_DATA,
} from "../../constants";
import { CurrencySearch, useCurrencyHook } from "./components";
import { Image } from "@storybook";
import styles from "./Currency.module.sass";
import { IAssetsState } from "../../../../states/exchange/type";
import { useLocation, useNavigate, useParams, useSearchParams } from "react-router-dom";
// @ts-ignore
import { Json } from "@types/common";
import { useWebSocket } from "@hooks/web-socket";
import { imageConfig } from "@utils/imageConfig";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import { getKeyValueObjectFromArray } from "@utils/common";
import { onValue, ref } from "firebase/database";
import { db } from "libs";
import { ENVIRONMENT } from "envs";
import { isValidUUID } from "@utils/check-valid-uuid";
import { ROUTES } from "@routes/constants";

type CurrencyProps = {
  setVisible?: React.Dispatch<React.SetStateAction<boolean>>;
};

export const Currency: React.FC<CurrencyProps> = ({ setVisible }) => {
  //global states
  const setVisibleCurrencyOverlay = useSetRecoilState(showCurrentOverlayInMobileState);
  const [activeTab, setActiveTab] = useRecoilState(ActiveAssetTab);
  const isManuHomeLoan = activeTab.key === assetTabs.MHL
  const [watchList, setWatchList] = useRecoilState(WatchlistState);
  const setStocks = useSetRecoilState(StockState);
  const [sba, setSba] = useRecoilState(SbaState);
  const [manuHomeLoan, setManuHomeLoan] = useRecoilState(ManuHomeLoanState);
  const [realEstate, setRealEstate] = useRecoilState(RealEstateState);
  const [equipmentFinance, setEquipmentFinance] = useRecoilState(EquipmentFinanceLoanState);
  const [musics, setMusics] = useRecoilState(MusicState);
  const setForex = useSetRecoilState(ForexState);
  const [privateState, setPrivatesState] = useRecoilState(PrivatesState);
  const [horsesState, setHorsesState] = useRecoilState(HorsesState);
  const [cryptoState, setCryptoState] = useRecoilState(CryptoState);
  const [secondaryMortgagesState, setSecondaryMortgagesState] = useRecoilState(SecondaryMortgagesState);
  const [activeCurrency, setActiveCurrency] =
    useRecoilState(ActiveCurrencyState);
  const setActiveCurrencyLoading = useSetRecoilState(ActiveCurrencyStateLoader)
  const firebaseIsFeatureEnable = useRecoilValue(firebaseIsFeatureEnableState);
  const setCommoditiesState = useSetRecoilState(CommoditiesState);
  const resetMHLFilters = useResetRecoilState(initialQueryMHLoanState);
  const resetEFFilters = useResetRecoilState(initialQueryEFLoanState);
  const resetHorsesFilters = useResetRecoilState(initialQueryHorsesState);
  const resetSba7Filters = useResetRecoilState(initialQuerySbaState);
  const resetPrivateFilters = useResetRecoilState(initialQueryPrivateState);
  const resetCryptoFilters = useResetRecoilState(initialQueryCryptoState);
  const promiseAllState = useRef(false);
  const darkMode = useDarkMode(false);
  const [assetLoading, setAssetLoading] = useState(false);
  const { errorNotification } = useNotification();
  const location = useLocation();
  const { svg: { no_data_light, no_data_dark }, logo: { Liquid } } = imageConfig;

    const { getAssetType } = useCurrencyHook();
  
  const params = useParams();
  const {NO_ROUTE} = ROUTES
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { socket } = useWebSocket();
  const currencyNavigationRef = useRef<HTMLDivElement>(null);
  const tabRef = useRef<(HTMLButtonElement | null)[]>([])
  const { SubRouteEnabled } = usePermissionContext();

  const { get: getWatchLists, data: watchLists, } = useNetwork();
  const { get: getSba, data: sbaData } = useNetwork();
  const { get: getManuHomeLoan, data: manuHomeLoanData } = useNetwork();
  const { get: getEquipmentFinanceLoan, data: equipmentFinanceLoanData } = useNetwork();
  const { get: getPrivates, data: privatesData } = useNetwork();
  const { get: getHorses, data: horsesData } = useNetwork();
  const { get: getCryptos, data: cryptoData } = useNetwork();
  const { get: getRealEstate, data: RealEstateData } = useNetwork();
  const { get: getAssetData } = useNetwork();
  const {config}=usePermissionContext()
  const { get, update, getAllIndexDbKeys } = useIndexedDB(
    INIT_CONFIGURATION_SAVE
  );
    const { get: getCookieData } = useCookie();
        const cookieData = useMemo(() => {
          return getCookieData("userDetails");
      }, []); // Empty dependency array ensures this runs only once
      
    
  

  const { STOCKS, WATCHLIST, FOREX, COMMODITIES, PRIVATES, HORSES, REALESTATE, MUSIC, SBA7, MHL, EF, CRYPTO, SM } =
    assetTabs;
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const socketUpdate = useMemo(() => {
    return Object.keys(privateState)?.length + Object.keys(cryptoState)?.length + Object.keys(realEstate)?.length + Object.keys(watchList)?.length
  }, [privateState, cryptoState, realEstate, watchList]);

  useEffect(() => {
    socket?.on("ASSET_PRICE_DATA", (data) => {
      const availableInDetailedView = activeCurrency?.id === data?.id;
      switch (data?.type) {
        case assestType.privates:
          if (!privateState[data?.id] && !availableInDetailedView) return
          handleSocketChange(data, setPrivatesState);
          break;
        case assestType.crypto:
          if (!cryptoState[data?.id] && !availableInDetailedView) return
          handleSocketChange(data, setCryptoState);
          break;
        case assestType.realEstate:
          if (!realEstate[data?.id] && !availableInDetailedView) return
          handleSocketChange(data, setRealEstate);
          break;
        case assestType.Horses:
          if (!horsesState[data?.id] && !availableInDetailedView) return
          handleSocketChange(data, setHorsesState);
          break;
        default:
          if (!watchList[data?.id] && !availableInDetailedView) return
          handleSocketChange(data, setWatchList, true);
          break;
      }
    });
    return () => {
      socket?.off("ASSET_PRICE_DATA");
    }
  }, [socket, activeCurrency?.id, socketUpdate, activeTab]);


  const handleSocketChange = (data: Json, setState: any, isWatchList?: boolean) => {
    if (activeCurrency?.id === data?.id) {
      setActiveCurrency((prev) => {
        return { ...prev, ...data ,type:prev?.type};
      });
    }

    if (activeTab.key !== ACTIVE_ASSET_TAB_WITH_SPACE[data?.type]) return;

    setState((prev: Json[]) => {
      let tempState = { ...(prev || {}) };
      if (tempState?.[data?.id]) {
        tempState[data?.id] = { ...tempState?.[data?.id], ...data };
        return tempState;
      }
      return prev;
    });
    if (!isWatchList) {
      setWatchList((prev) => {
        let tempState = { ...(prev || {}) };
        if (tempState?.[data?.id]) {
          tempState[data?.id] = { ...tempState?.[data?.id], ...data };
          return tempState;
        }
        return prev;
      })
    }
  }


  useEffect(() => {
    if(config){
      if(config?.name === MORNING_LINE){
        setAssetLoading(true);
        resetHorsesFilters();
        Promise.all([
          getHorses(`${APIS.Explorers}?include=horse_trade`),
          getWatchLists(`${APIS.Explorers}?include=watchlist`),
        ]).then(() => {
          promiseAllState.current = true
          setAssetLoading(false);
        })
      }else{
        setAssetLoading(true);
        resetMHLFilters();
        resetEFFilters();
        resetPrivateFilters();
        resetSba7Filters();
        resetCryptoFilters();
        Promise.all([
          getRealEstate(`${APIS.Explorers}?include=real_estate`),
          getPrivates(`${APIS.Explorers}?include=privates`),
          getCryptos(`${APIS.Explorers}?include=crypto`),
          getSba(`${APIS.Explorers}?include=sba7`),
          getManuHomeLoan(`${APIS.Explorers}?include=manufactured_home_loans`),
          getEquipmentFinanceLoan(`${APIS.Explorers}?include=equipment_finance`),
          getWatchLists(`${APIS.Explorers}?include=watchlist`),
        ]).then(() => {
          promiseAllState.current = true
          setAssetLoading(false);
        })
      }
    }
  }, [config]);

  useEffect(() => {
    if (promiseAllState.current) {
      if(config){
        if(config?.name === MORNING_LINE){
          const key = Object.keys(watchLists?.data ?? []);
          const tempHorses = getKeyValueObjectFromArray("id", horsesData?.data?.horse_trade || []);
          const tempWatchList = getKeyValueObjectFromArray("id", watchLists?.data?.[key[0]] || []);
          setHorsesState(tempHorses);
          setWatchList(tempWatchList);
          update(tempHorses, HORSES);
          update(tempWatchList, WATCHLIST);
        }else{
          const key = Object.keys(watchLists?.data ?? []);
          const tempPrivate = getKeyValueObjectFromArray("id", privatesData?.data?.privates || []);
          const tempRealEstate = getKeyValueObjectFromArray("id", RealEstateData?.data?.real_estate || []);
          const tempCrypto = getKeyValueObjectFromArray("id", cryptoData?.data?.crypto || []);
          const tempWatchList = getKeyValueObjectFromArray("id", watchLists?.data?.[key[0]] || []);
          const tempSba7 = getKeyValueObjectFromArray("id", sbaData?.data?.sba7 || []);
          const tempMhl = getKeyValueObjectFromArray("id", manuHomeLoanData?.data?.manufactured_home_loans || []);
          const tempFinance = getKeyValueObjectFromArray("id", equipmentFinanceLoanData?.data?.equipment_finance || []);

          setSba(tempSba7 ?? []);
          setManuHomeLoan(tempMhl ?? []);
          setEquipmentFinance(tempFinance ?? []);
          setPrivatesState(tempPrivate);
          setCryptoState(tempCrypto);
          setRealEstate(tempRealEstate);
          setWatchList(tempWatchList);
  
          update(tempPrivate, PRIVATES);
          update(tempCrypto, CRYPTO);
          update(tempRealEstate, REALESTATE);
          update(tempWatchList, WATCHLIST);
          update(tempSba7 || [], SBA7);
          update(tempMhl || [], MHL);
          update(tempFinance || [], EF);
        }
      }
      const { id, symbol, lender_loan_number } = activeCurrency;
      if (!(id || symbol || lender_loan_number) && !params.id) setDefaultTab(params.id);

    }
  }, [
    COMMODITIES,
    FOREX,
    MUSIC,
    PRIVATES,
    SBA7,
    STOCKS,
    privatesData?.data?.privates,
    horsesData?.data?.horse_trade,
    sbaData?.data?.sba7,
    manuHomeLoanData?.data?.manufactured_home_loans,
    equipmentFinanceLoanData?.equipment_finance,
    cryptoData?.data?.crypto,
    setCommoditiesState,
    setForex,
    setMusics,
    setPrivatesState,
    setSba,
    setStocks,
    watchLists?.data,
    setWatchList,
    update,
    WATCHLIST,
    promiseAllState.current,
    config
  ]);

  useEffect(() => {
    const fetchDetails = async () => {
      let id = params?.id;
      let assetFirebaseType = null;

      if ((isValidUUID(id ?? "") || !id) && !cookieData?.token) {
        navigate("/login");
        return;
      } else if (cookieData?.token && isValidUUID(id ?? "")) {
        navigate(NO_ROUTE)
      }

      if (id && !isValidUUID(params.id ?? "")) {
        const dataURef = ref(db, `/asset-data-${ENVIRONMENT}/${id?.toUpperCase()}`);
        const dataLRef = ref(db, `/asset-data-${ENVIRONMENT}/${id?.toLowerCase()}`);

        try {
          const dataUpper = await new Promise<{ id: string, type: string }>((resolve, reject) => {
            const unsubscribe = onValue(dataURef, (snapshot) => {
              const data = snapshot.val();
              unsubscribe(); // Unsubscribe after getting data
              resolve(data);
            }, reject);
          });

          const dataLower = await new Promise<{ id: string, type: string }>((resolve, reject) => {
            const unsubscribe = onValue(dataLRef, (snapshot) => {
              const data = snapshot.val();
              unsubscribe(); // Unsubscribe after getting data
              resolve(data);
            }, reject);
          });
          const data = dataUpper || dataLower;

          id = data?.id;
          assetFirebaseType = data?.type;

          if (!data && !cookieData?.token) {
            navigate("/login")
            return;
          }
        } catch (error) {
          console.error("Firebase error:", error);
          return;
        }
      }

      let assetType = location?.state?.assetType ?? assetFirebaseType ?? searchParams.get("type") ?? "Watchlist";
      const activeTab = ACTIVE_ASSET_TAB_WITH_SPACE[assetType] || assetType;
      const tempKey = assetType === "Watchlist" ? "Watchlist" : activeTab;

      setActiveTab({
        key: tempKey,
        index: assetsTab.findIndex((value) => value === tempKey),
      });

      if (secondaryMortgagesState[id as string]) {
        setActiveCurrency(SM_DETAIL_DATA as any);
        return;
      }

      setActiveCurrencyLoading(true);

      if (id) {
        setActiveCurrency({
          ...defaultActiveCurrency,
          type: assetType,
          category: assetType,
        } as any);

        getAssetData(`${APIS.ExchangeAssets}/${id}`).then((resp) => {
          if (
            resp?.message &&
            resp?.message !== "ok" &&
            assetType !== "Watchlist"
          ) {
            errorNotification(resp?.message);
          }

          if (resp?.data?.type) {
            setActiveCurrency({
              ...resp?.data,
              type: getAssetType(resp?.data),
              category: resp?.data?.type,
              marketPrice: resp.data?.amount,
            });
          } else {
            setDefaultTab(id, "", resp?.message);
          }
          setActiveCurrencyLoading(false);
        }).catch((err) => {
          errorNotification(err?.message);
          setActiveCurrencyLoading(false);
        });
      } else {
        setDefaultTab(id);
        setActiveCurrencyLoading(false);
      }
    };

    fetchDetails();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id]);
  
  const AvailableTabCheck = useCallback((indexDbKey: string) => {
    let tabsQuery=[];

    if(config?.name===MORNING_LINE){
     tabsQuery = [assetTabsInclude.HORSES_QUERY];
    }else{
      tabsQuery = [assetTabsInclude.PRIVATES_QUERY, assetTabsInclude.MUSIC_QUERY, assetTabsInclude.SBA7_QUERY]
    }

    const index = tabsQuery.findIndex((value) => value === indexDbKey)
    const nextActiveTab = tabsQuery[index + 1];
    if (nextActiveTab) {
      const activeTabKey = nextActiveTab.toUpperCase();
      setActiveTab({
        key: assetTabs[activeTabKey],
        index: assetsTab.findIndex((value) => value === assetTabs[activeTabKey]),
      })
      setDefaultTab("", nextActiveTab);
    } else {
      setActiveCurrency({} as IAssetsState);
    }
    
  }, [])


  const setDefaultTab = async (id: string | undefined, indexDbKey = "", errorMessage = "") => {
    const getIndexDbKeys: any = await getAllIndexDbKeys();
    if (!getIndexDbKeys?.length) return;
    let newID = id || "";
    const assetType =
      (!!newID && searchParams.get("type")) || indexDbKey || "Watchlist";
    const DBKey: string = ACTIVE_ASSET_TAB_WITH_SPACE[assetType] || ""
    get(DBKey).then((data: any) => {
      if (indexDbKey && !data) return setActiveCurrency({} as IAssetsState);
      let dbData = { ...(data || {}) };
      const dbDataKeys = Object.keys(dbData || {})
      if (dbDataKeys?.length && errorMessage === "Asset not found" && assetType === "Watchlist") {
        if (dbData[newID]) delete dbData[newID];
        update(dbData, WATCHLIST);
        newID = "";
      }
      // let idKey = !cookieData?.token ? "symbol" : "id";
      let idKey = "symbol";
      if (dbData && dbDataKeys?.length) {
        if (!newID) {
          return navigate(`/${dbData?.[dbDataKeys[0]]?.[idKey ?? ""]}${idKey === "id" ? `?type=${assetType}` : "" }`,{
            state: { assetType: assetType }
          });
        } else {
          const assetData = dbData[newID];
          if (!assetData) {
            return setActiveCurrency(({}) as IAssetsState);
          }
          return navigate(`/${assetData?.[idKey ?? ""]}${idKey === "id" ? `?type=${assetType}` : "" }`,
            {
            state: { assetType: assetType }
          }
        );
        }
        setActiveCurrency(({}) as IAssetsState);
      } else {
        AvailableTabCheck(indexDbKey)
      }
    });
  };

  // handle nav
  const handleClickNavigation = useCallback(
    (key: string, index: number) => {
      setActiveTab({ key, index });
      const element = tabRef.current[index]
      if (element !== null) {
        tabRef.current[index]?.scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
        })
      }
    },
    [setActiveTab]
  );

  const scrollButton = useCallback((value: number) => {
    if (currencyNavigationRef.current) {
      currencyNavigationRef.current.scrollLeft += value;
    }
  }, []);

  const handleScroll = useCallback((e: any) => {
    const navBar = e.target ?? {};
    const { scrollLeft } = navBar ?? {};
    const preList = document.querySelectorAll(".pre-arrow");
    const postList = document.querySelectorAll(".post-arrow");

    preList.forEach((pre) => {
      if (scrollLeft === 0) {
        pre?.classList.add(styles.hide);
      } else {
        pre?.classList.remove(styles.hide);
      }
    });

    postList.forEach((post) => {
      if (navBar?.scrollWidth <= scrollLeft + navBar?.clientWidth + 1) {
        post?.classList.add(styles.hide);
      } else {
        post?.classList.remove(styles.hide);
      }
    });
  }, []);

  const getCommonSubFeatures = (
    assetsTab: string[],
    enabledSubFeatures: string[]
  ): string[] => {
    if (!enabledSubFeatures.length) return assetsTab;

    return assetsTab.filter((subFeature) =>
      enabledSubFeatures.map((tab) => tab.toLowerCase()).includes(subFeature.toLowerCase()) // Compare in lowercase
    );
  };

  const renderNavigation = useMemo(() => {
    let assetPermissionTabs = getCommonSubFeatures(assetsTab, SubRouteEnabled("assets"))
    assetPermissionTabs = assetPermissionTabs.filter((tab: string) => firebaseIsFeatureEnable[ASSET_KEYS_BY_NAME[tab]] !== false)
    if(config && config?.name !== MORNING_LINE){
      assetPermissionTabs.push(SM);
    }
    if (!cookieData?.token) {
      assetPermissionTabs = (assetPermissionTabs ?? []).filter(item => item !== "Watchlist");
    }
    return (
      <div
        className={`${styles.nav} currency__nav`}
        ref={currencyNavigationRef}
        onScroll={handleScroll}
      >
        <button className={styles.pre_button} onClick={() => scrollButton(-50)}>
          <i className={`ri-arrow-left-s-line pre-arrow`} />
        </button>
        {assetPermissionTabs?.map((key: any, index: number) => (
          <button
            className={cn(
              styles.link,
              {
                [styles.active]: key === activeTab.key,
              },
              {
                [styles.sbaTab]: key === assestType?.SBA7,
              }
            )}
            ref={(el) => tabRef.current[index] = el}
            onClick={() => handleClickNavigation(key, index)}
            key={index}
          >
            {key === assetTabs.SBA7 ? SBA7A : key}
          </button>
        ))}
        <button className={styles.next_button} onClick={() => scrollButton(50)}>
          <i className={`ri-arrow-right-s-line  post-arrow`} />
        </button>
      </div>
    );
  }, [activeTab,cookieData?.token, handleClickNavigation, handleScroll, scrollButton, firebaseIsFeatureEnable,config]);

  const mapAssetDataStateAsPerTab: any = {
    "Watchlist": Object.values(watchList),
    "Private Stock": Object.values(privateState),
    "Horses": Object.values(horsesState),
    "Crypto": Object.values(cryptoState),
    "Real Estate": Object.values(realEstate),
    // "music": musics,
    "sba7": Object.values(sba),
    "Manufactured Home Loans": Object.values(manuHomeLoan),
    "Equipment Finance": Object.values(equipmentFinance),
    [SM]: Object.values(secondaryMortgagesState)
  }

  const renderAssets = useMemo(() => {
    if (!mapAssetDataStateAsPerTab[activeTab.key]?.length) {
      return (
        <>
          <div className={styles.noData}>
            <div className={styles.noDataContent}>
              <Image
                fileName={`${darkMode.value ? no_data_dark : no_data_light
                }`}
              />
              {activeTab.key === "Watchlist" ? (
                <>
                  <div className={styles.noDataHeading}>
                    Your Watchlist is empty
                  </div>
                  <div className={styles.noDataPara}>
                    Add assets to your watchlist to keep track of their
                    performance.
                  </div>
                </>
              ) : (
                <div>No Data Found</div>
              )}
            </div>
          </div>
        </>
      );
    } else {
      return (
        <div
          id="assets-scrollableDiv"
          className={styles.table}
          onClick={()=> setVisibleCurrencyOverlay(false)}
        >
          {<Assets />}
        </div>
      );
    }
  }, [watchList, privateState, musics, sba, manuHomeLoan, realEstate, horsesState, equipmentFinance, activeTab, cryptoState, secondaryMortgagesState,setVisible])

  const logo = (
    <div className={styles.logo1}>
      <img
        src={
          config?.name !== "Liquidity"
            ? darkMode.value
              ? config?.branding?.logo?.dark
              : config?.branding?.logo?.dark
            : Liquid
        }
        alt=""
        className={styles.brand_logo}
      />
      <i className="ri-menu-fold-line" onClick={()=> setVisibleCurrencyOverlay(false)}/>
    </div>
  );

  return (
    <div className={styles.currency}>
      {logo}
      <CurrencySearch />
      {renderNavigation}
      {assetLoading ? <SkeletonWatchList listsToRender={6} /> : renderAssets}
    </div>
  );
};
