import React, { useState } from "react";
import styles from "./monthlyStatement.module.sass";
import useMyDocumentApi from "../store/useMyDocumentApi";
import useDarkMode from "use-dark-mode";
import { imageConfig } from "@utils/imageConfig";
import { Loader } from "@components/Loader2";

interface MonthlyStatementProps {
  year: number;
}

const MonthlyStatement: React.FC<MonthlyStatementProps> = ({ year }) => {
  const months = [
    { name: "January", shortName: "Jan" },
    { name: "February", shortName: "Feb" },
    { name: "March", shortName: "Mar" },
    { name: "April", shortName: "Apr" },
    { name: "May", shortName: "May" },
    { name: "June", shortName: "June" },
    { name: "July", shortName: "Jul" },
    { name: "August", shortName: "Aug" },
    { name: "September", shortName: "Sep" },
    { name: "October", shortName: "Oct" },
    { name: "November", shortName: "Nov" },
    { name: "December", shortName: "Dec" },
  ];

  const {
    getMyDocument,
    loadingDownload,
    loadingView,
  } = useMyDocumentApi();
  const darkMode = useDarkMode();
  const {
    svg: { noInviteDark, noInviteLight },
  } = imageConfig;

  const getDaysInMonth = (month: number, year: number) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const formatDate = (date: Date) => {
    const options: Intl.DateTimeFormatOptions = {
      year: "numeric",
      month: "short",
      day: "2-digit",
    };
    return date.toLocaleDateString("en-US", options);
  };

  const handleDownload = async (month: any, year: any, download?: boolean) => {
    const monthIndex = months.findIndex((m) => m.name === month.name);
    if (monthIndex === -1) {
      console.error("Invalid month:", month.name);
      return;
    }

    const formattedMonth = (monthIndex + 1).toString().padStart(2, "0");

    // Start Date: Always 1st of the month
    const startDate = `${year}-${formattedMonth}-01`;

    // End Date: Last day of the month, manually formatted
    const lastDay = new Date(year, monthIndex + 1, 0);
    const endDate = `${lastDay.getFullYear()}-${formattedMonth}-${lastDay
      .getDate()
      .toString()
      .padStart(2, "0")}`;

    getMyDocument({
      startDate,
      endDate,
      download,
      loadingDownload: month.name,
      loadingView: month.name,
    });
  };

  // Dynamically restrict available months
  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth(); // 0-based (Jan = 0, Feb = 1, ..., Dec = 11)

  let filteredMonths = months;
  if (year === currentYear) {
    // Show only months up to the current month (inclusive)
    filteredMonths = months.slice(0, currentMonth + 1);
  } else if (year > currentYear) {
    // Future years → No months, show "No Data Available"
    filteredMonths = [];
  }

  return (
    <div className={styles.MonthlyStatementContainer}>
      {filteredMonths.length > 0 ? (
        filteredMonths.map((month, index) => {
          const daysInMonth = getDaysInMonth(index, year);
          const startDate = new Date(year, index, 1);
          const endDate = new Date(year, index, daysInMonth);

          return (
            <div key={index} className={styles.monthRow}>
              <div className={styles.monthDesc}>
                <div className={styles.monthHeader}>
                  {month.name} {year}
                </div>
                <div className={styles.dateRange}>
                  {formatDate(startDate)} – {formatDate(endDate)}
                </div>
              </div>
              <div className={styles.actions}>
                <button
                  className={styles.viewButton}
                  onClick={() => handleDownload(month, year, false)}
                >
                  {loadingView === month.name ? (
                    <Loader dimension={20} />
                  ) : (
                    <>
                      View <i className="ri-eye-line" />
                    </>
                  )}
                </button>
                <button
                  className={styles.downloadButton}
                  onClick={() => handleDownload(month, year, true)}
                >
                  {loadingDownload === month.name ? (
                    <Loader dimension={20} />
                  ) : (
                    "Download"
                  )}
                </button>
              </div>
            </div>
          );
        })
      ) : (
        // <div className={styles.noData}>No Data Available</div>
        <div className={styles.emptyStateMyDocumentContainer}>
          <div className={styles.emptyState}>
            <img src={darkMode.value ? noInviteDark : noInviteLight} alt="" />

            <div className={styles.emptyStateMyDocumentContainer__title}>
              No Data Available
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MonthlyStatement;
