// @ts-ignore
import { generate, sign } from "mpc-crypto-wallet-v1";
import { useCallback } from "react";
import { firestoreDb } from "libs";
import { doc, getDoc } from "firebase/firestore";
import { Json } from "../../../types/common";

const useMpcWalletApi = () => {
  const blockchainShard = async (number?: string) => {
    const keys = await generate();
    window.ReactNativeWebView &&
      Object.keys(keys ?? {}).length &&
      window.ReactNativeWebView.postMessage(JSON.stringify({ data: keys }));

    return keys;
  };

  const getFirebaseShards = useCallback(async (number: any) => {
    try {
      if (number) {
        const docRef = doc(firestoreDb, "shards", number);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          return docSnap.data();
        } else {
          console.log("No such document!");
        }
      }
    } catch (error: any) {
      console.error(error?.message);
    }
  }, []);

  const getSignHash = useCallback(async (shardskey: any, data: Json) => {
    try {
      const signHash = await sign(data, shardskey);
      return signHash;
    } catch (error) {
      console.log("error", error);
    }
  }, []);

  return { blockchainShard, getFirebaseShards, getSignHash };
};

export default useMpcWalletApi;