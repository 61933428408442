import { Json } from "../../../../types";
import { SeriesConfig } from "./type";

// Define series configurations
export const seriesConfigs: SeriesConfig[] = [
  {
    key: "unrealised_Pnl",
    name: "Unrealised Profit",
    color: "rgba(41, 163, 89, 0.24)",
    condition: (value) => value > 0, // Only positive unrealised values
  },
  {
    key: "realised_PnL",
    name: "Realised Profit",
    color: "rgba(41, 163, 89, 1)",
    condition: (value) => value > 0, // Only positive realised values
  },
  {
    key: "unrealised_PnL",
    name: "Unrealised Loss",
    color: "rgba(233, 53, 53, 0.24)",
    condition: (value) => value < 0, // Only negative unrealised values
  },
  {
    key: "realised_PnL",
    name: "Realised Loss",
    color: "rgba(233, 53, 53, 1)",
    condition: (value) => value < 0, // Only negative realised values
  },
];

export const seriesConfigsPortfolio: SeriesConfig[] = [
  {
    key: "invested_value",
    name: "Invested",
    color: "rgba(20, 143, 225, 1)",
    condition: (value) => value > 0, // Only positive unrealised values
  },
  {
    key: "market_value",
    name: "Current",
    color: "rgba(143, 20, 225, 1)",
    condition: (value) => value > 0, // Only positive realised values
  },
];

