import React, { useCallback, useMemo } from "react";

import "./all-portfolio.scss";
import { ASSETS_COLOR, CHART_COLORS, PORTFOLIO_ASSETS } from "../../constants";
import { useCurrency } from "../../../../hooks";
import { useFormatNumber } from "../../../../utils";
import { useRecoilValue } from "recoil";
import {
  firebaseIsFeatureEnableState,
  PortfolioListState,
  userSelectedCurrencyState,
} from "../../../../states";
import { SkeletonTable, SkeletonWatchList } from "components";
import { Json } from "../../../../types";
import { VariablePieChart } from "components/Charts";
import { FortressAccountInfo } from "views/MyWallet/store/state";
import { Image } from "@storybook";
import useDarkMode from "use-dark-mode";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { assetTabs, MORNING_LINE, SBA7A } from "@views/exchange/constants";
import { imageConfig } from "@utils/imageConfig";
import classNames from "classnames";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";


interface IAllPortfolio {
  onClickAsset: (str: string) => void;
}

export const AllPortfolio = ({ onClickAsset }: IAllPortfolio) => {
  const { formatCurrencyWithBillion, formatNumberWithBillion } = useCurrency();
  const { percentFormat } = useFormatNumber();
  const selectedCurrency = useRecoilValue(userSelectedCurrencyState);
  const infoData = useRecoilValue<any>(FortressAccountInfo);
  const firebaseIsFeatureEnable = useRecoilValue(firebaseIsFeatureEnableState);
  const darkMode = useDarkMode(false);
  const { config } = usePermissionContext();
  const isMLC = config?.name === MORNING_LINE;
  let { data: portfolioData, loading } = useRecoilValue(PortfolioListState);
  portfolioData = portfolioData?.data || {};

  const CashProfitLoss = 0;

  const {svg: {no_chart_light, no_chart}} = imageConfig;
  
  const {
    investedValue,
    gainLoss,
    gainLossPercentage,
    chartData,
    chartColors,
    portfolioBalance,
  } = useMemo(() => {
    const chartData: Json = [];
    const chartColors: string[][] = [];
    const portfolioKeys = Object.keys(portfolioData);
    const finalData = Object.values(portfolioData as Json).reduce(
      (acc = {}, crr = {}, index) => {
        if (!PORTFOLIO_ASSETS[portfolioKeys[index]] || firebaseIsFeatureEnable[portfolioKeys[index]] === false) return acc;
        acc.investedValue += crr?.summary?.investedValue ?? 0;
        acc.gainLoss += crr?.summary?.gainLoss ?? 0;
        acc.gainLossPercentage += crr?.summary?.gainLossPercentage ?? 0;
        chartColors.push(CHART_COLORS[portfolioKeys[index]]);
        return acc;
      },
      {
        investedValue: 0,
        gainLoss: 0,
        gainLossPercentage: 0,
      }
    );
    const portfolioBalance =
      finalData.investedValue + finalData.gainLoss + (infoData?.balance ?? 0);
    const totalBase = finalData.investedValue + (infoData?.balance ?? 0);

    Object.values(portfolioData as Json).forEach((crr, index) => {
      if (!PORTFOLIO_ASSETS[portfolioKeys[index]] || firebaseIsFeatureEnable[portfolioKeys[index]] === false) return;
      const value = crr?.summary?.investedValue || 0;
      const percent = Number(((value / totalBase) * 100).toFixed(2));
      const gainLossPercentageSign =
        (crr?.summary?.gainLossPercentage ?? 0) > 0
          ? "+"
          : (crr?.summary?.gainLossPercentage ?? 0) < 0
            ? ""
            : " ";
      const gainLossPercentageColor =
        (crr?.summary?.gainLossPercentage ?? 0) > 0
          ? "green"
          : (crr?.summary?.gainLossPercentage ?? 0) < 0
            ? "red"
            : "gray";
      chartData.push({
        name: portfolioKeys[index] === "sba7" ? SBA7A : PORTFOLIO_ASSETS[portfolioKeys[index]] || "",
        assetType: portfolioKeys[index],
        y: percent || 0,
        z: Math.abs(crr?.summary?.currentValue ? 10 + index + 1 : 0),
        current: formatCurrencyWithBillion(crr?.summary?.currentValue, 2) || 0,
        value,
        gainLoss: formatCurrencyWithBillion(crr?.summary?.gainLoss, 2) || 0,
        gainLossPercentage:
          formatNumberWithBillion(gainLossPercentageSign + crr?.summary?.gainLossPercentage, 2) || 0,
        gainLossPercentageColor,
      });
    });


    //----------------------------------------------------------------------------
    chartColors.push(CHART_COLORS.cash);
    chartData.push({
      name: "cash",
      assetType: "cash",
      y: Number(((infoData?.balance / totalBase) * 100).toFixed(2)) || 0,
      z: 14,
      current: formatCurrencyWithBillion(infoData?.balance?.toFixed(2), 2) || 0,
      value: infoData?.balance,
      gainLoss: formatCurrencyWithBillion(CashProfitLoss.toFixed(2), 2) || 0,
      gainLossPercentage: CashProfitLoss.toFixed(2),
    });
    return { ...finalData, chartData, chartColors, portfolioBalance };
  }, [portfolioData, infoData?.balance, formatCurrencyWithBillion, firebaseIsFeatureEnable]);

  // TODO: change this function when backend made changes to issue name keys
  const getAssetName = (assetName: any, id: string) => {
    if (id) return assetName.split(" ")[0] // if music id is present then return return music name first word
    const arr = assetName?.split(" ");
    return arr[1] || arr[0];
  };

  /*const renderAssetImages: any = useCallback((assets: any) => {
    let assetCount = assets.length;
    let newAssetArray = assets.slice(0, 3);
    let assetImageOverlay;
    assetImageOverlay = newAssetArray?.map((items: any, index: any) => {
      return (
        <Image
          key={assets[index]?.logo}
          url={assets[index]?.logo}
          initials={getAssetName(assets[index]?.name, assets[index]?.musicId)}
          className="assets-image"
          initialsClassName="assets-image"
        />
      );
    });

    if (assetCount > 3) {
      assetImageOverlay.push(<span className="assets-image-len">{assets.length - 3}+</span>)
    }

    return assetImageOverlay;
  }, [])*/

  const renderCards = useMemo(() => {
    const assetsArray = Object.keys(PORTFOLIO_ASSETS);
    const sortedValue  = Object.keys(portfolioData).sort((a, b) => {
      return assetsArray.indexOf(a) - assetsArray.indexOf(b);
    });
    return sortedValue?.map((key) => {
      if (!PORTFOLIO_ASSETS[key] || firebaseIsFeatureEnable[key] === false) return null;
      const summary = portfolioData?.[key]?.summary || {};
      // const assets = portfolioData?.[key]?.assets || [];
      const summaryValue=parseFloat(summary.investedValue)

      return (
        <div className="assets-container" key={key}>
          <div  className={classNames("assets-heading",!summaryValue ?"portfolio-disabled":"")} onClick={() => !!summaryValue && onClickAsset(key)}>
            <span
              className="label"
              style={{ borderLeftColor: ASSETS_COLOR[key] }}>
              {PORTFOLIO_ASSETS[key]}
            </span>
            <i className="ri-arrow-right-s-line arrow-icon" />
          </div>
          <div className="asset-details">
            <div className="asset-detail">
              <label className="asset-detail_label">Current</label>
              <div className="asset-detail_value">
                {formatCurrencyWithBillion(summary.currentValue, 2)}
              </div>
            </div>
            <div className="asset-detail">
              <label className="asset-detail_label">Invested</label>
              <div className="asset-detail_value">
                {formatCurrencyWithBillion(summary.investedValue, 2)}
              </div>
            </div>
            <div className="asset-detail">
              <label className="asset-detail_label">P&L</label>
              <div className="asset-detail_value">
                {formatCurrencyWithBillion(summary.gainLoss, 2)}&nbsp;
                <span
                  className={
                    (summary.gainLossPercentage ?? 0) > 0
                      ? "positive"
                      : (summary.gainLossPercentage ?? 0) < 0
                        ? "negative"
                        : "neutral"
                  }
                >
                  (
                  {(summary.gainLossPercentage ?? 0) > 0
                    ? "+"
                    : (summary.gainLossPercentage ?? 0) < 0
                      ? "-"
                      : ""}
                  {percentFormat(summary.gainLossPercentage)})
                </span>
              </div>
            </div>
            {/*<div className="assets-images">{renderAssetImages(assets)}</div>*/}
          </div>
        </div>
      );
    });
  }, [portfolioData, selectedCurrency, firebaseIsFeatureEnable]);

  const renderAssetList = useMemo(() => {

    const assetsArray = [...Object.keys(PORTFOLIO_ASSETS), "cash"];
    const sortedVlaue =  [...(chartData || [])].sort((a, b) => {
      return assetsArray.indexOf(a.assetType) - assetsArray.indexOf(b.assetType);
    });
    return sortedVlaue?.map((data: Json) => {
      if (!(PORTFOLIO_ASSETS[data?.assetType] || data?.assetType === "cash") || firebaseIsFeatureEnable[data?.assetType] === false) return;
      return (
        <li className="portfolio-asset" key={data?.name}>
          <div
            className="asset-detail"
            style={{ borderLeftColor: ASSETS_COLOR[data?.assetType] }}
          >
            <label className="asset-detail_label">
              {data?.name === "privates" ? "Private Stock" : data?.name === "sba7" ? SBA7A : data?.name}
            </label>
            <div className="asset-detail_value">{percentFormat(data.y)}</div>
          </div>
          {/* <div className="assets-price">
            {formatCurrencyWithBillion(data.value, 2)}
          </div> */}
        </li>
      );
    });
  }, [portfolioData, selectedCurrency, chartData, infoData, firebaseIsFeatureEnable ]);

  const renderGainLossPercentage = useMemo(() => {
    return (gainLoss / investedValue) * 100;
  }, [gainLoss, investedValue]);

  return (
    <div className={isMLC ? "mlc_all-portfolio-container" : "all-portfolio-container"}>
      <div className={isMLC? "mlc_portfolio-cart-list-container" :"portfolio-cart-list-container"}>
        <div className="portfolio-card-list-wrapper">
          <div className="portfolio-chart-image">
            {!loading &&
              (portfolioBalance ? (
                <VariablePieChart
                  name="Portfolio"
                  colors={chartColors}
                  chartData={chartData}
                />
              ) : (
                <Image
                  className="portfolio-chart-image__no-data-image"
                  fileName={darkMode.value ? no_chart : no_chart_light}
                />
              ))}
          </div>
          <div className={isMLC ? "mlc_current-and-portfolio" : "current-and-portfolio"}>
            <div className="asset-detail text-center">
              <div className="asset-detail_value justify-content-center fs-20">
                {formatCurrencyWithBillion(portfolioBalance, 2)}
              </div>

              <label className="asset-detail_label">
                Portfolio Value{" "}
                <i
                  id={portfolioBalance}
                  className="ri-information-fill asset-detail_InfoIcon"
                />
                <ReactTooltip
                  style={{ zIndex: 101 }}
                  anchorId={portfolioBalance}
                  className="theme-tooltip portfolio-tooltip"
                  place="bottom"
                  content={"Current value of all owned assets + Cash balance"}
                />{" "}
              </label>
            </div>
            <div className="asset-detail text-center">
              <div className="asset-detail_value justify-content-center fs-20">
                {formatCurrencyWithBillion(infoData?.balance, 2)}
              </div>
              <label className="asset-detail_label">Cash Balance</label>
            </div>
          </div>
          <div className={isMLC ? "mlc_portfolio-chart-prices" : "portfolio-chart-prices"}>
            <div className="asset-detail">
              <label className="asset-detail_label">Invested</label>
              <div className="asset-detail_value">
                {formatCurrencyWithBillion(investedValue, 2)}
              </div>
            </div>
            <div className="asset-detail">
              <label className="asset-detail_label">P&L</label>
              <div className="asset-detail_value">
                {formatCurrencyWithBillion(gainLoss, 2)}&nbsp;
                <span
                  className={
                    (renderGainLossPercentage ?? 0) > 0
                      ? "positive"
                      : (renderGainLossPercentage ?? 0) < 0
                      ? "negative"
                      : "neutral"
                  }
                >
                  (
                  {(renderGainLossPercentage ?? 0) > 0
                    ? "+"
                    : (renderGainLossPercentage ?? 0) < 0
                    ? "-"
                    : ""}
                  {percentFormat(renderGainLossPercentage)})
                </span>
              </div>
            </div>
          </div>
        </div>
        <ul className="portfolio-assets">
          {loading && <SkeletonTable listsToRender={3} numberColumn={2} />}
          {!loading && renderAssetList}
        </ul>
      </div>
      <div className="all-assets">
        {loading && <SkeletonWatchList listsToRender={5} />}
        {!loading && renderCards}
      </div>
    </div>
  );
};
