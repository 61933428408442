import React, { useEffect } from "react";
import { useRecoilValue } from "recoil";
import { Loader, NoData } from "components";
import { useNavigate } from "react-router-dom";
import useDarkMode from "use-dark-mode";
import { imageConfig } from "@utils/imageConfig";

import "../../my-wallet.scss";
import { AllTransactionsData } from "@views/MyWallet/store/state";
import { UseTransaction } from "@views/MyWallet/store/hooks";
import { TransactionCard } from "../TransactionCard";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import { MORNING_LINE } from "@views/exchange/constants";

export const RecentTransaction = () => {
  const navigate = useNavigate();
  const darkMode = useDarkMode(false);

  const navigateToTransactoons = () => {
    navigate("/wallet/transactions");
  };

  const allTranstions = useRecoilValue(AllTransactionsData);
  const { getTransactions } = UseTransaction();
  const { config } = usePermissionContext();
  const isMLC = config?.name === MORNING_LINE;
  const {
    svg: { no_data_light, no_data_dark },
  } = imageConfig;
  
  useEffect(() => {
    const options = { updateNewData: false };
    const query = {
      limit: 20,
      offset: 0,
    };
    getTransactions(query, options);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <div className={isMLC ? "mlc_wallet" : "wallet"}>
      <div
        className={
          isMLC
            ? "mlc_wallet-transaction-details-container"
            : "wallet-transaction-details-container"
        }
      >
        <div className="transaction-details-header">
          <p>Recent Transactions</p>
          {allTranstions?.data?.length !== 0 && allTranstions && (
            <small onClick={navigateToTransactoons}>See all</small>
          )}
        </div>
        <div className="transaction-container">
          {allTranstions?.loading ? (
            <Loader />
          ) : allTranstions?.data?.length > 0 ? (
            allTranstions.data
              .filter((transaction: any, index: number) => index < 8)
              .map((transaction: any) => (
                <TransactionCard
                  transactionType={transaction?.type}
                  transactiomnStatus={transaction?.status}
                  amount={transaction?.amount}
                  bankName={transaction?.bankName}
                  transactionDate={transaction?.createdAt}
                  flow={transaction?.flow}
                  transactionId={transaction?.transactionId}
                  id={transaction?.id}
                  accountNumber={transaction?.accountNumber}
                  intendedTransaction={transaction?.intendedTransaction}
                />
              ))
          ) : (
            <NoData
              img={darkMode.value ? no_data_dark : no_data_light}
              description="No transactions yet."
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default RecentTransaction;
