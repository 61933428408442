import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import React, { Suspense, useEffect } from "react";
import { ROUTES } from "routes";

import {
  LoginPhoneNumberState,
  LoginUserState,
  TriggerUserApi,
  darkThemeState,
  maintenanceState,
  userDetailsState,
  userDropDownState,
  userLockedState,
  userPersonalDetails,
  userProfileState,
  UsersLoadingState,
  userAllDetailsState,
  firebaseIsFeatureEnableState,
  userSelectedCurrencyState,
  TennetTokenState,
} from "states";
import { useCookie, useLocalStorage, useNetwork, useNotification } from "hooks";

import Exchange from "@views/exchange/exchange";
import SignInForm from "../views/SignIn/components/signin-form/signin-form";
import ChooseMethod from "../views/SignIn/components/choose-method/choose-method";
import OtpVerify from "../views/SignIn/components/Form/otp-verify";
import MobileLinkSent from "../views/SignIn/components/mobile-link-sent/mobile-link-sent";
import BiometricApprovalSent from "../views/SignIn/components/biometric-approval-sent/biometric-approval-sent";
import NewUserOtpVerify from "../views/SignIn/components/newUserOtpVerify/new-user-otp-verify";
import Account from "../views/account/AccountOverview";
import AdminDashboard from "../views/admin-dashboard/admin-dashboard";
import MyPortfolioRoutes from "../views/MyPortfolio/MyPortfolioRoutes";
import Reports from "../views/report/reports";
import AuctionConsumer from "../views/AuctionConsumer/AuctionConsumer";
import UserBidDetails from "../views/AuctionConsumer/UserBidDetails/UserBidDetails";
import UserBidAuctions from "../views/AuctionConsumer/UserBidAuctions/UserBidAuctions";
import AuctionDetails from "../views/AuctionConsumer/AuctionDetails/AuctionDetails";
import RemovalProcess from "../views/account/components/AuthorisedUser/components/removalProcess/removalProcess";
import Invitation from "../views/account/components/AuthorisedUser/components/invitation/invitation";
import RemovalRequest from "../views/account/components/AuthorisedUser/components/removalRequest/removalRequest";
import RequestCancel from "../views/account/components/AuthorisedUser/components/removalRequest/requestCancel";
import MobileApprove from "../views/SignIn/components/mobile-approve/mobile-approve";
import MyOrders from "../views/MyOrders/MyOrders";
import OrderDetails from "../views/MyOrders/components/order-details";

import { APIS, USER_DETAILS } from "constant";
import { Loader } from "components";
import StaticFileServe from "views/staticFileServeComponent/staticFileServe";
import { Layout } from "@components/Layout";
import { TermsOfService } from "@views/SignIn/components/terms-of-service";
import { PrivacyPolicy } from "@views/SignIn/components/privacy-policy";
import { BiometricPolicy } from "@views/SignIn/components/biometric-policy";
import { AccessDenied, SocialCallBackComponent, Maintenance } from "views";
import { EmailVerify } from "@components/EmailVerification";
import MpcQr from "@views/SignIn/components/mpc-wallet/mpc-qr";
import MpcSetupStatus from "@views/SignIn/components/mpc-wallet/mpc-setup-status";
import MpcMobileRedirect from "@views/SignIn/components/mpc-wallet/mpc-mobile-redirect";
import { ContactUs } from "@views/SignIn/components/contact-us";
import SwitchAccount from "@views/SignIn/components/SwitchAccount/SwitchAccount";
import ProtectedRoute from "@components/protectedRoute/ProtectedRoute";
import { removeLocalStorage } from "@utils/common";
import { onValue, ref } from "firebase/database";
import { db } from "../libs";
import { ENVIRONMENT } from "../envs";
import MpcWallet from "@views/mpc-wallet/mpc-wallet";
import { AccountDeletionForm } from "@components/RquestAccounDelete";

export const AllRoutes = () => {
  const {
    PROFILE,
    LOGIN,
    AUTH,
    AUTH_WITH_TYPE,
    CHOOSEMETHOD,
    MOBILELINKSENT,
    BIOMETRICAPPROVALSENT,
    NEWUSEROTPVERIFY,
    OTPVERIFY,
    REPORTS,
    NO_ROUTE,
    ADMIN,
    ORDERSPAGE,
    ORDERDETAILS,
    AUCTION,
    AUCTION_DETAILS,
    USER_BID_LIST,
    USER_BID_DETAILS,
    COOWNERS,
    COOWNERSREMOVE,
    MOBILE_VERIFICATION,
    COOWNERSINVITE,
    COOWNERSREMOVALREQUEST,
    COOWNERSREMOVALREQUESTCANCEL,
    TERMS_SERVICE,
    PRIVACY_POLICY,
    BIOMETRIC_POLICY,
    MPC_WALLET,
    CONTACT_US,
    ACCESS_DENIED,
    WALLET_ROUTE,
    EMAILVERIFY_DENIED,
    MPC_QR,
    MPC_SETUP_STATUS,
    MPC_MOBILE_REDIRECT,
    SWITCH_ACCOUNT,
    REQUEST_ACCOUNT_DELETE,
  } = ROUTES;
  const setUserInfo = useSetRecoilState(LoginUserState);
  const userLock = useRecoilValue(userLockedState);
  const triggerUserApi = useRecoilValue(TriggerUserApi);

  const { get } = useCookie();

  const userDetails = get("userDetails") ?? {};
  const themeMode = localStorage.getItem("darkMode");
  const isActiveUser = useRecoilValue(userDropDownState);
  const [{ isAdmin, phone, countryCode, id }, setPersonalData] =
    useRecoilState(userPersonalDetails);
  const setLoginDetailUser = useSetRecoilState(LoginPhoneNumberState);
  const setUserDetail = useSetRecoilState(userDetailsState);
  const [maintenance, setMaintenance] = useRecoilState(maintenanceState);

  const { deleteCookie } = useCookie();

  const { get: getUser, data: userData, isLoaded: userLoaded } = useNetwork();
  const { get: getMaintenance } = useNetwork();

  const { set: setCookieToken } = useCookie();
  const { put: loginInvite } = useNetwork();
  const { successNotification } = useNotification();
  const location = useLocation();
  const { errorNotification } = useNotification();
  const [themeDark, setThemeDark] = useRecoilState(darkThemeState);
  const { get: getCookieData } = useCookie();
  const tennetToken = useRecoilValue(TennetTokenState);

  const setProfilePic = useSetRecoilState(userProfileState);
  const setUserLoading = useSetRecoilState(UsersLoadingState);
  const setUserDetails = useSetRecoilState(userAllDetailsState);
  const setSelectedCurrency = useSetRecoilState(userSelectedCurrencyState);
  const setFirebaseIsFeatureEnable = useSetRecoilState(
    firebaseIsFeatureEnableState
  );
  const { set: setLocalStorage } = useLocalStorage();
  const firebaseIsFeatureEnable = useRecoilValue(firebaseIsFeatureEnableState);

  useEffect(() => {
    getMaintenance(`${APIS.APP_VERSION}`).then((res) => {
      if (res?.data) {
        setMaintenance(res.data);
      } else {
        errorNotification(res?.message);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const dataRef = ref(db, `is-feature-enable-${ENVIRONMENT}`);
    const unsubscribe = onValue(dataRef, (snapshot) => {
      const data = snapshot.val();
      setFirebaseIsFeatureEnable(data || {});
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const userDetails = getCookieData("userDetails");
    if (userDetails?.token) {
      getUser(APIS.Users);
    }
  }, [getCookieData, getUser, triggerUserApi]);

  useEffect(() => {
    if (userData?.data) {
      const ownerId = userData?.data?.coOwnerId;
      setUserDetail(userData);
      localStorage.setItem("coOwnerId", ownerId);
    }
  }, [setUserDetail, userData, userData?.data]);

  useEffect(() => {
    setUserLoading(true);
    if (userData?.data) {
      const {
        settings = "",
        phone,
        countryCode,
        firstName,
        lastName,
        issuerName,
        email,
        onboardingData,
        id,
        isAdmin,
        isBlockChat,
        isVerifiedEmail,
        profileImage,
        isTradeEnabled,
        isTransactionEnabled,
        polygonId,
        accounts,
        walletAddress,
        watchlistId,
        customerId,
        dvp,
        isEmptyProfileImage,
      } = userData.data;
      setSelectedCurrency(settings.currency);
      setProfilePic(profileImage);
      setLoginDetailUser({
        phone,
        countryCode,
      });
      const data = {
        ...userData.data,
        firstName,
        lastName,
        issuerName,
        email,
        phone,
        currency: settings?.currency?.code,
        countryCode,
        kyc: onboardingData?.kycStatus,
        kyb: onboardingData?.kybStatus,
        address: onboardingData?.fullAddress,
        amlStatus: onboardingData?.amlStatus,
        accreditationStatus: onboardingData?.accreditationStatus,
        signDocStatus: onboardingData?.signDocStatus,
        id,
        isAdmin,
        isVerifiedEmail,
        profileImage,
        isTradeEnabled,
        isTransactionEnabled,
        polygonId,
        isBlockChat,
        accounts,
        walletAddress,
        watchlistId,
        customerId,
        onboardingData,
        dvp,
        isEmptyProfileImage,
      };
      setPersonalData(data);
      setLocalStorage(USER_DETAILS, data);
      setUserLoading(false);
      setUserDetails(userData?.data);
    }
  }, [userData?.data]);

  useEffect(() => {
    const userDetails = getCookieData("userDetails");
    if (phone && countryCode && userDetails) {
      setLoginDetailUser({
        phone: phone,
        countryCode: countryCode,
      });
    }
  }, [countryCode, phone, setLoginDetailUser]);

  useEffect(() => {
    const userData = get("userDetails");
    if (userData?.isLoggedIn) {
      setUserInfo({
        phone: "",
        token: userData.token,
        isLoggedIn: userData.isLoggedIn,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    themeMode === null && localStorage.setItem("darkMode", "true");
  }, [themeMode]);

  useEffect(() => {
    if (
      location?.pathname.includes("/co-owners-email-accept/") &&
      tennetToken
    ) {
      const urlId = location.pathname.split("/");
      const inviteId = urlId[urlId.length - 1];

      loginInvite(`${APIS.COOWNER}/${inviteId}`).then((res) => {
        if (res?.token) {
          const registrations = JSON.parse(
            localStorage.exchageAppRegistration || "[]"
          );
          themeMode === "true" ? setThemeDark(true) : setThemeDark(false);
          removeLocalStorage();
          deleteCookie("userDetails");
          localStorage.setItem(
            "exchageAppRegistration",
            JSON.stringify(registrations)
          );
          const loginDetails = {
            token: res?.token,
            phone: "",
            isLoggedIn: true,
          };
          setCookieToken("userDetails", loginDetails);
          setUserInfo(loginDetails);
          successNotification(res?.message);
        } else {
          errorNotification(res?.message);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tennetToken]);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        {maintenance?.isAppMaintenance ? (
          <Route path="*" element={<Maintenance />} />
        ) : (
          <>
            <Route
              path="/.well-known/apple-app-site-association"
              element={<StaticFileServe />}
            />
            <Route path={MOBILE_VERIFICATION} element={<MobileApprove />} />
            <Route path={EMAILVERIFY_DENIED} element={<EmailVerify />} />
            <Route path={CONTACT_US} element={<ContactUs />} />
            <Route path={SWITCH_ACCOUNT} element={<SwitchAccount />} />
            <Route path={TERMS_SERVICE} element={<TermsOfService />} />
            <Route path={PRIVACY_POLICY} element={<PrivacyPolicy />} />
            <Route path={BIOMETRIC_POLICY} element={<BiometricPolicy />} />
            <Route path={MPC_WALLET} element={<MpcWallet />} />
            {!userDetails?.token ? (
              <>
                <Route path={AUTH} element={<SocialCallBackComponent />} />
                <Route
                  path={AUTH_WITH_TYPE}
                  element={<SocialCallBackComponent />}
                />
                <Route path={LOGIN} element={<SignInForm />} />
                <Route path={CHOOSEMETHOD} element={<ChooseMethod />} />
                <Route path={OTPVERIFY} element={<OtpVerify />} />
                <Route path={MOBILELINKSENT} element={<MobileLinkSent />} />
                <Route
                  path={BIOMETRICAPPROVALSENT}
                  element={<BiometricApprovalSent />}
                />
                <Route path={NEWUSEROTPVERIFY} element={<NewUserOtpVerify />} />
                <Route path={COOWNERS} element={<Exchange />} />
                <Route path={COOWNERSREMOVE} element={<RemovalProcess />} />
                <Route path={MPC_QR} element={<MpcQr />} />
                <Route path={MPC_SETUP_STATUS} element={<MpcSetupStatus />} />
                <Route
                  path={MPC_MOBILE_REDIRECT}
                  element={<MpcMobileRedirect />}
                />
                <Route
                  path={REQUEST_ACCOUNT_DELETE}
                  element={
                    <Layout isShowHeader>
                      <AccountDeletionForm />
                    </Layout>
                  }
                />
                <Route
                  path={`/exchange/:id`}
                  element={
                    <Navigate to={`/${location?.pathname?.split("/")?.[2]}`} />
                  }
                />
                <Route
                  path={`/:id`}
                  element={
                    <Layout isShowHeader>
                      <Exchange />
                    </Layout>
                  }
                />

                <Route path="*" element={<Navigate to={LOGIN} />} />
              </>
            ) : (
              <>
                {userLoaded ? (
                  <>
                    {userLock ? (
                      <>
                        <Route
                          path={ACCESS_DENIED}
                          element={<AccessDenied />}
                        />
                        <Route
                          path="/*"
                          element={<Navigate to={ACCESS_DENIED} />}
                        />
                      </>
                    ) : (
                      <>
                        <Route path="/" element={<Layout />}>
                          <Route index element={<Exchange />} />
                          <Route
                            path={ORDERSPAGE}
                            element={
                              <ProtectedRoute tabName="orders">
                                <MyOrders />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path={ORDERDETAILS}
                            element={
                              <ProtectedRoute tabName="orders">
                                <OrderDetails />
                              </ProtectedRoute>
                            }
                          />
                          <Route
                            path={WALLET_ROUTE}
                            element={
                              <ProtectedRoute tabName="wallet">
                                <MyPortfolioRoutes />
                              </ProtectedRoute>
                            }
                          />
                          <Route path={AUCTION} element={<AuctionConsumer />} />
                          <Route
                            path={AUCTION_DETAILS}
                            element={<AuctionDetails />}
                          />
                          <Route
                            path={USER_BID_LIST}
                            element={<UserBidAuctions />}
                          />
                          <Route
                            path={USER_BID_DETAILS}
                            element={<UserBidDetails />}
                          />

                          {firebaseIsFeatureEnable?.report && (
                            <Route
                              path={REPORTS}
                              element={
                                <ProtectedRoute tabName="reports">
                                  <Reports />
                                </ProtectedRoute>
                              }
                            />
                          )}

                          <Route path={PROFILE} element={<Account />} />
                          <Route
                            path={ADMIN}
                            element={
                              isAdmin &&
                              Object.keys(isActiveUser || {})?.length > 0 &&
                              isActiveUser?.isPrimary ? (
                                <AdminDashboard />
                              ) : (
                                <Navigate to={NO_ROUTE} />
                              )
                            }
                          />
                          <Route
                            path={ACCESS_DENIED}
                            element={<AccessDenied />}
                          />
                          <Route
                            path={REQUEST_ACCOUNT_DELETE}
                            element={<AccountDeletionForm />}
                          />
                          <Route
                            path={`/exchange/:id`}
                            element={
                              <Navigate
                                to={`/${location?.pathname?.split("/")?.[2]}`}
                              />
                            }
                          />
                          <Route path={`/:id`} element={<Exchange />} />
                        </Route>

                        <Route path={COOWNERS} element={<Exchange />} />
                        <Route
                          path={COOWNERSREMOVE}
                          element={<RemovalProcess />}
                        />
                        <Route path={COOWNERSINVITE} element={<Invitation />} />
                        <Route
                          path={COOWNERSREMOVALREQUEST}
                          element={<RemovalRequest />}
                        />
                        <Route
                          path={COOWNERSREMOVALREQUESTCANCEL}
                          element={<RequestCancel />}
                        />

                        <Route path="*" element={<Navigate to="/" />} />
                      </>
                    )}
                  </>
                ) : (
                  <Route path="*" element={<Loader />} />
                )}
              </>
            )}
          </>
        )}
      </Routes>
    </Suspense>
  );
};
