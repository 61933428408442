import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";

import "./portfolio-assets.scss";
import { useCurrency, useNetwork, useNotification } from "../../../../hooks";
import {
  convertToDays,
  formatNumberWithCommas,
  getJsonToParams, getKeyValueObjectFromArray,
  mergeStringWithSymbol,
  useFormatNumber
} from "../../../../utils";
import { CountdownTimer, SkeletonWallet } from "components";
import { Json } from "../../../../types";
import { Image } from "../../../../@storybook";
import useDarkMode from "use-dark-mode";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../../../../routes";
import InfiniteScroll from 'react-infinite-scroll-component';
import { ASSET_TYPE, noDataMessage } from "@views/MyPortfolio/constants";
import { APIS } from "../../../../constant";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import { imageConfig } from "@utils/imageConfig";
import { assestType, ASSETS_CONFIG, MORNING_LINE } from "@views/exchange/constants";
import { useWebSocket } from "@hooks/web-socket";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";

interface IPortfolioAssets {
  activeAsset: string;
}

export const PortfolioAssets = ({ activeAsset }: IPortfolioAssets) => {
  const initialQuery = { limit: 20, offset: 0 };
  const navigate = useNavigate();
  const { formatCurrencyWithBillion } = useCurrency();
  const { socket } = useWebSocket();
  const { percentFormat } = useFormatNumber();
  const [searchParams] = useSearchParams();
  const darkMode = useDarkMode(false);
  const { get: fetchPortfolioDataList, loading } = useNetwork()
  const [portfolioData, setPortfolioData] = useState<Json>({});
  const assetWiseFilter = portfolioData?.[activeAsset]?.filters || {}
  const { errorNotification } = useNotification();
  const abortControllerRef = useRef<any>(null);
  const { config } = usePermissionContext();
  const assetList: Json[] = useMemo(() => {
    return Object.values(portfolioData?.[activeAsset]?.data || {})
  }, [activeAsset, portfolioData]);
  const isMLC = config?.name === MORNING_LINE;
  useEffect(() => {
    socket?.on("ASSET_PRICE_DATA", (data) => {
      switch (data?.type) {
        case ASSET_TYPE.CRYPTO:
          if (activeAsset !== ASSET_TYPE.CRYPTO) return
          if (!portfolioData?.[activeAsset]?.data?.[data?.id]) return
          setPortfolioData(prev => {
            let tempData = prev;
            if (tempData?.[activeAsset]?.data?.[data?.id]) {
              const currentValue = data?.amount * tempData?.[activeAsset]?.data?.[data?.id]?.quantity;
              const gainLoss = currentValue - tempData?.[activeAsset]?.data?.[data?.id]?.investedValue;
              const gainLossPercentage = (gainLoss / tempData?.[activeAsset]?.data?.[data?.id]?.investedValue) * 100
              tempData[activeAsset].data[data?.id] = {
                ...tempData?.[activeAsset]?.data?.[data?.id],
                currentValue, gainLoss, gainLossPercentage,
                currentPrice: data?.amount || 0,
              }
              return {...tempData}
            }
            return prev
          })
          break;
      }
    });
    return () => {
      socket?.off("ASSET_PRICE_DATA");
    }
  }, [socket, activeAsset]);

  useEffect(() => {
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    abortControllerRef.current = new AbortController();
    if (!portfolioData[activeAsset]) {
      handleFetchMore();
    }

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [activeAsset]);

  const handleFetchMore = useCallback(async () => {
    const { signal } = abortControllerRef.current;
    let assetFilter: Json = { ...(assetWiseFilter || {}), type: activeAsset };
    let pageChange: Json = !!portfolioData?.[activeAsset] ?
      { ...assetFilter, offset: assetFilter?.offset + 1 } : { ...initialQuery, type: activeAsset }
    const res = await fetchPortfolioDataList(`${APIS.PORTFOLIO_SUMMARY + getJsonToParams(pageChange)}`, { signal });
    if (res?.message === "ok") {
      setPortfolioData(prev => {
        let tempData = prev;
        const tempObj = getKeyValueObjectFromArray("id", res?.data || []);
        tempData[activeAsset] = {
          filters: pageChange,
          data: !!tempData[activeAsset] ? { ...tempData?.[activeAsset]?.data, ...tempObj } : tempObj
        }
        return { ...tempData }
      })
    } else {
      !!res && errorNotification(res?.message || "Something went wrong!");
    }

  }, [activeAsset, portfolioData, abortControllerRef.current])

  const handleClickAsset = useCallback(
    async (asset: Json, index: number) => {
      console.log("🚀 ~ asset:", asset)
      // const activeTab = searchParams.get("tab");
      navigate(`/${asset?.symbol}`);
    },
    [navigate]
  );

  const renderLockInTime = useCallback(({ days, hours, minutes, seconds, completed }: Json) => {
    if (completed) {
      // update state after timer is over
    }
    const time = completed ? 'No Lockup' : `${!!days ? days + "D :" : ""} ${
      (hours < 10 ? "0" : "") + hours
    }H : ${(minutes < 10 ? "0" : "") + minutes}M : ${(seconds < 10 ? "0" : "") + seconds}S`;
    return (
      <div className="lockInCount">
        <CircularProgressbarWithChildren
          value={100} maxValue={100} className="countCircle" strokeWidth={6} counterClockwise>
        </CircularProgressbarWithChildren>
        <div>
          <span className="timeColor portfolio-asset-label_value">{time}</span>
        </div>
      </div>
    );
  }, []);

  const lockInTime = useCallback((lockupPeriod: any, tradeUnlockTime: any) => {
    const durationObj = convertToDays(
      lockupPeriod,
      tradeUnlockTime
    );
    return durationObj?.futureDate;
  }, []);


  const renderAssetList = useMemo(() => {
    const decimal = ASSETS_CONFIG[activeAsset as string]?.qtyDecimal
    return (assetList || [])?.map(
      (asset: Json, index: number) => {
        const { issuerDetails = {}, symbol, name } = asset || {}
        const issuerNameString = activeAsset === assestType.crypto ? "" : issuerDetails?.issuerName;
        return (
          <li
            className={isMLC ? "mlc_portfolio-asset" : "portfolio-asset"}
            key={asset?.assetId}
            onClick={() => handleClickAsset(asset, index)}
          >
            <div>
              <div className="portfolio-asset-details">
                <Image
                  url={asset?.logo}
                  initials={asset?.symbol}
                  className="logo"
                  initialsClassName="logo-name"
                />
                <div className="pl-10">
                  <div className="portfolio-asset-symbol">
                    {asset?.musicId
                      ? symbol : config?.name === "Morning Line" ? symbol
                      : mergeStringWithSymbol(
                        issuerNameString,
                        symbol?.toUpperCase(),
                        "."
                      )}
                  </div>
                  <span className="portfolio-asset-name">
                    {asset?.musicId
                      ? name : config?.name === "Morning Line" ? name
                      : mergeStringWithSymbol(
                        issuerNameString,
                        name,
                        " "
                      )}
                  </span>
                </div>
              </div>
              <div className="portfolio-asset-label">
                Basis{" "}
                <span className="portfolio-asset-label_value">
                  {formatCurrencyWithBillion(asset?.investedValue, decimal)}
                </span>
              </div>
              <div className="portfolio-asset-label">
                Qty.{" "}
                <span className="portfolio-asset-label_value">
                  {formatNumberWithCommas(asset?.quantity, decimal, false)}
                </span>
              </div>
              {
                Number(asset?.lockupPeriod?.duration) > 0 &&
                asset?.lockInTradeInfo?.tradeUnlockTime && (
                  <div className="order-lockup">
                    <p className="portfolio-asset-label">Lockup : </p>
                    <CountdownTimer
                      dateTime={lockInTime(
                        asset?.lockupPeriod,
                        asset?.lockInTradeInfo?.tradeUnlockTime
                      )}
                      renderer={renderLockInTime}
                    />
                  </div>
                )
              }
            </div>
            <div className="portfolio-asset-prices">
              <div className="portfolio-asset-label fw-600">
                {/* Current&nbsp; */}
                <span className="portfolio-asset-label_value_white">
                  {formatCurrencyWithBillion(asset?.currentValue, decimal)}
                </span>
              </div>
              <div className="portfolio-asset-label">
                Current Price&nbsp;
                <span className="portfolio-asset-label_value">
                  {formatCurrencyWithBillion(
                    asset?.lastTradedPrice || asset?.currentPrice,
                    decimal
                  )}
                </span>
                &nbsp;
                {/* <span  TODO:// commenting if in future old changes needed, otherwise can delete
                  className={
                    (asset?.changePercentage ?? 0) > 0
                      ? "positive"
                      : (asset?.changePercentage ?? 0) < 0
                      ? "negative"
                      : "neutral"
                  }
                >
                  (
                  {(asset?.changePercentage ?? 0) > 0
                    ? "+"
                    : (asset?.changePercentage ?? 0) < 0
                    ? "-"
                    : ""}
                  {percentFormat(asset?.changePercentage)})
                </span> */}
              </div>
              <div className="portfolio-asset-label">
                Cost Basis Price&nbsp;
                <span className="portfolio-asset-label_value">
                  {formatCurrencyWithBillion(asset?.averagePrice, decimal)}
                </span>
              </div>
              <div className="portfolio-asset-label">
                P&L&nbsp;
                <span className="portfolio-asset-label_value">
                  {formatCurrencyWithBillion(asset.gainLoss, decimal)}
                </span>
                &nbsp;
                <span
                  className={
                    (asset.gainLossPercentage ?? 0) > 0
                      ? "positive"
                      : (asset.gainLossPercentage ?? 0) < 0
                        ? "negative"
                        : "neutral"
                  }
                >
                  (
                  {(asset.gainLossPercentage ?? 0) > 0
                    ? "+"
                    : (asset.gainLossPercentage ?? 0) < 0
                      ? "-"
                      : ""}
                  {percentFormat(asset.gainLossPercentage)})
                </span>
              </div>
              <div className="portfolio-asset-types">
                {/* {ASSET_TYPE.MHL === activeAsset && <span>MHL</span>} */}
                {asset?.sbaType && (
                  <span>
                    {asset?.sbaType?.split("_")?.join(" ")?.toLowerCase()}
                  </span>
                )}
              </div>
            </div>
          </li>
        );
      }
    );
  }, [assetList, activeAsset, formatCurrencyWithBillion, percentFormat, handleClickAsset]);

  const getNoDataMessage = useMemo(() => {
    if (activeAsset === ASSET_TYPE.PRIVATES) {
      return noDataMessage.NO_PRIVATES_DATA;
    } else if (activeAsset === ASSET_TYPE.HORSES) {
      return noDataMessage.NO_HORSE_DATA;
    } else if (activeAsset === ASSET_TYPE.MUSIC) {
      return noDataMessage.NO_MUSIC_DATA;
    } else if (activeAsset === ASSET_TYPE.SBA7) {
      return noDataMessage.NO_SBA7_DATA;
    } else if (activeAsset === ASSET_TYPE.REALESTATE) {
      return noDataMessage.NO_REALESTATE_DATA;
    } else if (activeAsset === ASSET_TYPE.SBA7) {
      return noDataMessage.NO_SBA7_DATA;
    } else if (activeAsset === ASSET_TYPE.MHL) {
      return noDataMessage.NO_MHL_DATA;
    } else if (activeAsset === ASSET_TYPE.EF) {
      return noDataMessage.NO_EF_DATA;
    } else if (activeAsset === ASSET_TYPE.CRYPTO) {
      return noDataMessage.NO_CRYPTO_DATA;
    }
  }, [activeAsset]);

  const { svg: { no_data_light, no_data_dark } } = imageConfig;

  return ( 
    <ul className="portfolio-assets-container">
      {!loading && !assetList?.length && (
        <>
          <Image
            className="no-data-image"
            fileName={
              darkMode.value
                ? no_data_dark
                : no_data_light
            }
          />
          <div className="no-data-msg">{getNoDataMessage}</div>
        </>

      )}
      <InfiniteScroll
        dataLength={assetList?.length || 0} //This is important field to render the next data
        next={handleFetchMore}
        hasMore={assetList?.length === (assetWiseFilter.offset * assetWiseFilter.limit) + assetWiseFilter.limit}
        loader={<SkeletonWallet listsToRender={1} />}
        scrollableTarget="portfolio-assets-scroll">
        {renderAssetList}
      </InfiniteScroll>
      {loading && <SkeletonWallet listsToRender={3} />}
    </ul>
  );
};
