import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { LoginPhoneNumberState } from "states";
import { LoginUserState, MobileVerificationState } from "@states/user";
import { Loader } from "@components/Loader2";
import { useMobileAuthentication } from "@views/SignIn/hooks/useMobileAuthentication";
import { useNotification } from "@hooks/notification";
import { useCookie } from "@hooks/cookie";
import { ROUTES } from "@routes/constants";

import "./mobile-link-sent.scss";
import { useSwitchWorkSpace } from "@views/SignIn/hooks/useSwitchWorkSpace";
import { useHandleSignInFireBase } from "libs";
import { Image } from "../../../../@storybook";
import { imageConfig } from "@utils/imageConfig";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import classNames from "classnames";
import styles from "@views/SignIn/components/Form/Form.module.sass";
import { CountdownTimer } from "@components/counter";
// @ts-ignore
import { Json } from "@types/common";
import { addSeconds } from "date-fns";
import { formatPhoneNumber } from "@utils/format-number";
import { MORNING_LINE } from "@views/exchange/constants";

export const MobileLinkSent = () => {
  const [verificationId, setMobileVerificationState] = useRecoilState(MobileVerificationState);
  const loginDetails = useRecoilValue(LoginPhoneNumberState);
  const { phone, countryCode } = loginDetails;
  const { checkMobileVerificationStatus } = useMobileAuthentication();
  const { successNotification, errorNotification } = useNotification();
  const { set: setCookieToken } = useCookie();
  const { SWITCH_ACCOUNT,NO_ROUTE } = ROUTES;
  const setLoginUser = useSetRecoilState(LoginUserState);
  const { fetchWorkSpaceUser } = useSwitchWorkSpace();
  const { handleSigninFireBase } = useHandleSignInFireBase();
  const {logo: {LiquidityLogo}, gif:{verificationLink}} = imageConfig;
  const { config } = usePermissionContext();
  const [sendCodeTimer, setSendCodeTimer] = useState<any>(null);
  const { generateVerificationLink, loading: mobileVerificationLinkLoading } = useMobileAuthentication();
  const isMLC = config?.name === MORNING_LINE;

  const { LOGIN } = ROUTES

  const navigate = useNavigate();

  let mobileLinkVerifyInterval: any = useRef(null);

  const loginUser = async (token: string) => {
    const loginDetails = {
      token,
      isLoggedIn: true,
    };
    if (token) {
      setCookieToken("userDetails", loginDetails);
      const res = await fetchWorkSpaceUser();
      if (res?.message !== "ok") return;
      if (res?.data?.length > 1) {
        navigate(SWITCH_ACCOUNT, { state: { token, response: res } });
        return;
      }

      successNotification("Logged In Successfully");
      setLoginUser((prevState) => {
        const newState = {
          ...prevState,
          isLoggedIn: true,
          token,
        };
        return newState;
      });
      navigate(NO_ROUTE);
    }
  };

  const checkStatus = async () => {
    const payload = {
      type: "getTokenWithCode",
      verificationId,
    };

    const res = await checkMobileVerificationStatus(payload);
    if (res) {
      const { status, token } = res?.data;
      if (token && status === "approved") {
        // stop the interval and login the user
        clearInterval(mobileLinkVerifyInterval.current);
        loginUser(token);
        handleSigninFireBase(token)
      }
      if (status === "rejected") {
        // stop the interval and login the user
        clearInterval(mobileLinkVerifyInterval.current);
        errorNotification("Login Verification Denied");
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    if (verificationId) {
      mobileLinkVerifyInterval.current = setInterval(() => {
        checkStatus();
      }, 5000);
    }

    return () => clearInterval(mobileLinkVerifyInterval.current);
  }, [verificationId]);

  const cancelApproval = () => {
    clearInterval(mobileLinkVerifyInterval.current);
    navigate(-1);
  };

  useEffect(() => {
    if (!phone) {
      navigate(LOGIN);
    }
  }, [phone, navigate]);

  const handleMobileApp = useCallback(async () => {
    if (mobileVerificationLinkLoading) return;
    const verificationId = await generateVerificationLink({
      type: "phone",
      countryCode,
      phone,
    });
    if (verificationId) {
      successNotification(`Approval request resent successfully to ${countryCode}${formatPhoneNumber(countryCode + phone)}`);
      setSendCodeTimer(addSeconds(new Date(), 30));
      setMobileVerificationState(verificationId);
    }
  }, [mobileVerificationLinkLoading]);

  const renderTimer = useCallback(({ minutes, seconds, completed }: Json) => {
    if (completed) setSendCodeTimer(null);
    return `${(seconds < 10 ? "0" : "") + seconds}`;
  }, []);


  return (
    <div className={isMLC ? "mlc_mobile-link" : "mobile-link"}>
      <Image
        className={`brand_logo`} url={config?.name !== "Liquidity" ? config?.branding?.logo?.dark : LiquidityLogo} />
      <div className={isMLC ? "mlc_mobile-link__wrapper" : "mobile-link__wrapper"} >
        <span className="back-btn" onClick={() => navigate(-1)}><i className={classNames("ri-arrow-left-line backIcon")} />Back</span>
        <div className="mobile-link__row">
          <div className="mobile-link__title">Approval request sent to your phone</div>
          <div className="mobile-link__description">
            {/* {`We have sent you a text message to ${country} ${phone} . Open the link
                provided in the text message`} */}
            A text message with an approval link has been sent to <span>{countryCode} {phone ? phone : 'Redirecting...'}</span>. Click the link and approve to sign in.
            {/* We have sent a text message to {countryCode} {phone ? phone : 'Redirecting...'}.
            <strong className="mobile-link__mobile-number"> </strong>Open the
            link provided in the text message and approve from there. */}
          </div>
        </div>
        <div className="mobile-link__row mobile-link__row-1 mobile-link__vertical-space">
          <img src={verificationLink} alt="approval-sent" />
          {/* <div className="mobile-link__number">{securityCode}</div>
          <div className="mobile-link__specification">
            Tap <strong>{securityCode}</strong> on your phone to verify your
            phone number{" "}
          </div> */}
        </div>

        <div className={styles.resend}>
          {!sendCodeTimer && <>
            <span className={styles.resendText}>Didn't receive the passcode? </span>
            <span className={styles.editBtn} onClick={handleMobileApp}>
                {mobileVerificationLinkLoading ?
                  <Loader dimension={18} className="loader-white" /> : "Resend"}
              </span>
          </>}

          {!!sendCodeTimer && <>
            <span className={styles.resendText}>{`Didn't receive the passcode?`}</span>
              <span className={styles.resendText}>Resend in</span>
              <div className="style.send_timer">
              <span className={styles.sendCodeTimer}>
                00
              </span>
            <span className={styles.sendCodeTimerDivide}>
                :
              </span>
            <span className={styles.sendCodeTimer}>
                <CountdownTimer
                  dateTime={sendCodeTimer}
                  renderer={(value) => renderTimer(value)}
                />
              </span>
              </div>
          </>}
        </div>
        {/* <div className="mobile-link__row">
          <button onClick={cancelApproval}>Cancel</button>
        </div> */}
      </div>
    </div>
  );
};
export default MobileLinkSent
