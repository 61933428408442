import { ApiResponse, SeriesConfig, TransformedData } from "./type";

export const transformApiResponseToChartData = (
  response: ApiResponse,
  seriesConfigs: SeriesConfig[],
  key: string
): TransformedData => {
  const intervalSummary = response.data[key];
 
  // Extract categories (x-axis labels)
  const categories = intervalSummary.map((item: any) => item.start_date);

  // Generate series based on provided configurations
  const series = seriesConfigs.map((config) => ({
    name: config.name,
    data: intervalSummary.map((item: any) => {
      const value = item[config.key] as number;
      return config.condition ? (config.condition(value) ? Math.abs(value) : 0) : value;
    }),
    color: config.color,
  }));

  return { categories, series };
};
