import React, { useRef, useState } from "react";
import DatePicker from "react-multi-date-picker";
import styles from "./myDocuments.module.sass";
import classNames from "classnames";
import MyDocumentsNavigation from "./components/myDocumentsNavigation";
import MonthlyStatement from "./components/monthlyStatement";
import useDarkMode from "use-dark-mode";
import "./myDocuments.scss";
const MyDocuments = () => {
  const [selectedYear, setSelectedYear] = useState<number>(
    new Date().getFullYear()
  );
  const [activeTab, setActiveTab] = useState<string>("Monthly Statements");
  const datePickerRef = useRef<any>(null);
  const darkMode = useDarkMode();

  const tabComponents: Record<string, React.FC<any>> = {
    // "Investment Documents": "",
    "Monthly Statements": MonthlyStatement,
    // "Tax Forms": "",
  };
  const ActiveComponent = tabComponents[activeTab];
  const activeComponentProps =
    activeTab === "Monthly Statements"
      ? {
          year: selectedYear,
        }
      : {};
  const openDatePicker = () => {
    if (datePickerRef.current) {
      datePickerRef.current.openCalendar();
    }
  };
  const handleYearChange = (date: any) => {
    setSelectedYear(date.year);
  };
  return (
    <div className={classNames(styles.myDocuments__container, "myDocuments")}>
      <div className={styles.myDocumentsSection}>
        <div className={styles.myDocumentsSection__title}>My Documents</div>
        <div className={styles.myDocumentsSection__datePicker}>
          <div className={styles.myDocumentsSection__datePicker__year}>
            {selectedYear}
          </div>
          <div className={styles.myDocumentsSection__datePicker__calendar}>
            <DatePicker
              ref={datePickerRef}
              onlyYearPicker={true}
              onChange={handleYearChange}
              containerClassName={styles.datePickerContainer}
              className={classNames(
                styles.myDocumentsDatePicker,
                darkMode.value ? styles.myDocuments__darkMode : styles.lmyDocuments__lightMode
              )}
              inputClass={styles.myDocumentsSection__datePickerInput}
              minDate={new Date(2025, 0, 1)}
            />
            <i
              className={classNames(
                "ri-calendar-line",
                styles.myDocumentsSection__datePicker__calendar__icon
              )}
              onClick={openDatePicker}
            />
          </div>
        </div>
      </div>
      <div>
        <MyDocumentsNavigation
          activeTab={activeTab}
          onTabChange={setActiveTab}
        />
        {ActiveComponent && <ActiveComponent {...activeComponentProps} />}
      </div>
    </div>
  );
};

export default MyDocuments;
