import { useNetwork } from "@hooks/network";
import { useNotification } from "@hooks/notification";
import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";
import { AccountState, PlaidTokenState } from "@states/wallet";
import { APIS } from "constant";
import { useCallback } from "react";
import {
  PlaidLinkOnSuccess,
  PlaidLinkOptions,
  usePlaidLink,
} from "react-plaid-link";
import { useRecoilState, useSetRecoilState } from "recoil";

export const useAppPlaidLink = () => {
  const { trackEvent } = useFullStoryTrackEvent();
  const { post: submitExchangeToken, loading: bankProceedLoader } =
    useNetwork();
  const { successNotification, errorNotification } = useNotification();
  const {
    get: getAccounts,
    loading: acccountGetLoading,
    data: accountsList,
  } = useNetwork();
  const [token, setToken] = useRecoilState(PlaidTokenState);
  const setAccounts = useSetRecoilState(AccountState);

  const onSuccess = useCallback<PlaidLinkOnSuccess>(
    async (publicToken, metadata) => {
      let payload = {
        bankName: metadata.institution?.name,
        linkToken: token,
        token: publicToken,
      };


      const response = await submitExchangeToken(APIS.TokenExchange, payload);
      if (response.id) {
        trackEvent("NEW_BANK_ADDED", {
          ...payload,
        });
        successNotification("Account linked successfully");
      } else {
        trackEvent("NEW_BANK_ADD_ERROR", {
          ...response,
        });
        errorNotification(response?.message ?? "Something went wrong");
      }
      setToken("");
      getAccounts(APIS.Accounts).then((res) => {
        if (res?.data) {
          setAccounts(res.data);
          window.parent.postMessage(
            {
              type: "trigger",
              message: "plaidResponseDetails",
              data: {
                accounts: res.data,
                publicToken,
                metadata,
              },
            },
            "*"
          );
        }
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [token]
  );

  const config: PlaidLinkOptions = {
    token,
    onSuccess,
    onExit: (error) => {
      console.log('error', error);
    },
  };

  const { open } = usePlaidLink(config);

  return { open, accountsList, acccountGetLoading, bankProceedLoader };
};
