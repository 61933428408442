import React, { useEffect, useMemo, useState } from "react";
import { Slide, ToastContainer } from "react-toastify";
import { useMediaQuery } from "react-responsive";

import { initDB, INIT_CONFIGURATION, useCookie } from "hooks";
import { AllRoutes } from "./routes/routes";
import Mobileview from "views/mobile-view";
import "react-toastify/dist/ReactToastify.css";
import "react-circular-progressbar/dist/styles.css";
import { EmailVerifyNotification, KycSignupModal, ReactModal } from "./components";
import { useLocation } from "react-router-dom";
import useDarkMode from "use-dark-mode";
import MobileEmailVerification from "@views/mobile-email-verify";
import MobileCoownerJoining from "@views/mobile-co-owner-joining";
import { useFirebaseNotification } from "@hooks/useFirebaseNotification";
import { logEvent } from "firebase/analytics";

import "./styles/app.sass";
import "variables/_variables.scss";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  isFormVisibleState,
  isPrivateModalState,
  isUserLoginPopupVisibleState,
  kybModalState,
  LoginUserState,
  onboardingCompleteBannerState,
  OpenKycReviewModalState,
  OpenMintAppState,
  OpenPublishNowReviewState,
  refAtomCaptable,
  reOpenKycSignupModalState,
  showUserEditModalState,
  TennetTokenState,
  userPersonalDetails,
  userTypeState,
} from "./states";
import ContinueInMobile from "@views/continueInMobile";
import { useFullStoryTrackEvent } from "./hooks/useFullStoryTrack";

import MpcWallet from "@views/mpc-wallet/mpc-wallet";
import MpcMobileRedirect from "@views/SignIn/components/mpc-wallet/mpc-mobile-redirect";
import AccountApprovalBanner from "@views/exchange/components/AccountApprovalBanner/accountApprovalBanner";
import { loadGTM, trackEventSG } from "libs";
import styles from "./App.module.sass"

import { ENVIRONMENT, REACT_APP_API_HOST } from "envs";
import { CLIENT_ENVIRONMENT } from "@routes/constants";

import Hubspot from "@components/Header/component/HubSpot/hubspot";
import { useVerificationsHook } from "@hooks/useVerificationsHook";
import classNames from "classnames";
import { KycReview } from "@views/exchange/components/compliance/kyc-review";
import { VerificationSteps } from "@views/verificationStep";
import { EditUserDetailsModal } from "@views/account/components/AccountBalances/editProfile";
import { ErrorBoundary } from "react-error-boundary";
import { ErrorPage } from "@views/error-page/error-page";
import { PublishNowReview } from "@views/exchange/components/compliance/Publish-now-review";
import MintApp from "@views/mint/mint-app";
import { REACT_APP_API_HOST as API_HOST } from "envs";
import { InjectScript } from "./views/admin-dashboard/utils";
import { UpgradeKyb } from "@views/exchange/components/Actions/Form/UpgradeKyb";
import { isMobile } from "@utils/common";
import { LoginPopUpModal } from "@views/exchange";
import NewModal from "@components/new-modal/new-modal";
import "./views/exchange/components/login-pop-up-modal/login-pop-up-modal.scss";
import { PrivateModal } from "@components/privateModal/privateModal";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import { MORNING_LINE } from "@views/exchange/constants";



initDB(INIT_CONFIGURATION);

const App = (props : any) => {
  const { pathname } = useLocation();
  const location = useLocation();
  const { config } = usePermissionContext();
  const isMLC = config?.name === MORNING_LINE;
  const { onMessageListener } = useFirebaseNotification();
  const userDetails = useRecoilValue(userPersonalDetails);
  const editPrivate = useRecoilValue(isPrivateModalState);
  
  const reOpenKycSignupModal = useRecoilValue(reOpenKycSignupModalState);
  const  [OpenKycReviewModal,setOpenKycReviewModal]= useRecoilState(OpenKycReviewModalState);
   const accountVerify = useRecoilValue(onboardingCompleteBannerState);
   const [isUserLoginPopupVisible , setUserLoginPopupVisible] = useRecoilState(isUserLoginPopupVisibleState);
  const { isOnboardingComplete = true, } =
  userDetails?.onboardingData || {};
  const { setUserIdentifire } = useFullStoryTrackEvent();
  const { get: getCookieData } = useCookie();
  const userDetail = getCookieData("userDetails");
  const { token: loginToken } = userDetail ?? {};
  // const isSmallScreen = useMediaQuery({ query: "(max-width: 767px) and (min-width: 375px)" });
  const isFormVisible = useRecoilValue(isFormVisibleState)
  const { handleVerifySwitchAccountEmail } = useVerificationsHook();
  const setTennetToken = useSetRecoilState(TennetTokenState)
  const  showUserEditModal = useRecoilValue(showUserEditModalState);
  const [openPublishNowReview,setOpenPublishNowReview]=useRecoilState(OpenPublishNowReviewState)
  const [openMintApp,setOpenMintApp]=useRecoilState(OpenMintAppState)
  const kybModalVisible = useRecoilValue(kybModalState);
  const refCaptable = useRecoilValue(refAtomCaptable);
  const { isLoggedIn } = useRecoilValue(LoginUserState);
  const isMobileScreen = window.innerWidth <= 480;

      const  API = (typeof API_HOST !== "undefined" && API_HOST)
          ? API_HOST
          : REACT_APP_API_HOST || "";

      useEffect(() => {
        if(openMintApp){
          const script = new InjectScript({hostUrl: API})
          script.inject();
        }
      }, [openMintApp])

  useEffect(() => {
    const clientId = props?.props?.clientid;
    setTennetToken(clientId)
  }, [props?.props?.clientid])

  const isSmallScreen = useMediaQuery({
    query: "(max-width: 767px) and (min-width: 375px)",
  });

  const allowedDesktopView = useMemo(() => {
    if (
      /mobile-verification|privacy-policy|terms-of-service|biometric-policy/.test(
        pathname
      )
    ) {
      return true;
    } else if (
      isSmallScreen &&
      /co-owners-email-remove|co-owner-removal-request|co-owner-request-cancel/.test(
        pathname
      )
    ) {
      return true;
    }
    return false;
  }, [pathname, isSmallScreen]);

  useEffect(() => {
    handleVerifySwitchAccountEmail();
  }, []);

  useEffect(() => {
    if (loginToken) {onMessageListener();}
  }, [loginToken, onMessageListener]);

  useEffect(() => {
    const clientId = props?.props?.clientid;
 
    if (clientId) {
      sessionStorage.setItem("tenantToken", JSON.stringify(clientId));
     }
  }, [])


  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const utmSource = urlParams.get("utm_source");

    const eventName = loginToken ? "Dashboard_page_view_web" : "Login_page_view_web"

    trackEventSG(
      eventName,
      {
        page_url: location.pathname, utm_source: utmSource
      },
      userDetails?.email
    );
  }, [location]);


// Only for Exectives we will remove it later once all GA4 events setup.
  useEffect(() => {
    // Execute GTM script if:
    // 1. Environment is 'prod'
    // 2. No email is provided OR the email does not contain 'satschel.com'
    if (
      ENVIRONMENT === CLIENT_ENVIRONMENT.prod &&
      (!userDetails?.email || !userDetails?.email?.includes("satschel.com"))
    ) {
      loadGTM();
    }
  }, [userDetails?.email]);

  useEffect(() => {
    if (userDetails.id) {
      setUserIdentifire(userDetails.id, {
        displayName:
          !!userDetails.firstName && !!userDetails.lastName
            ? `${userDetails.firstName} ${userDetails.lastName}`
            : userDetails.phone,
        email: !!userDetails.email ? userDetails.email : userDetails.phone,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails.email, userDetails.firstName, userDetails.id, userDetails.lastName, userDetails.phone]);

  const hideKycSignUpModal = useMemo(() => {
    return /co-owners-email-remove|co-owner-removal-request|co-owner-request-cancel|login/.test(
      pathname
    );
  }, [pathname]);

  const MobileComponent = useMemo(() => {
    if (/email-verify/.test(pathname)) return MobileEmailVerification;
    else if (/mpc-wallet/.test(pathname)) return MpcWallet
    else if (/continue-in-mobile/.test(pathname)) return ContinueInMobile;
    else if (/co-owners-email-accept/.test(pathname))
      return MobileCoownerJoining;
    else if (/mpc-mobile-redirect/.test(pathname)) return MpcMobileRedirect;

    return Mobileview;
  }, [pathname]);

  const showEmailNotification = useMemo(() => {
    return !/mobile-verification/.test(pathname);
  }, [pathname]);

  const darkMode = useDarkMode(true);

  const rendertheme = useMemo(() => {  
    return darkMode.value ? "dark" : "light";
  }, [darkMode]);

  useEffect(() => {
    const openPublishNow= ()=>{
      setOpenPublishNowReview(true)
    }
    window.addEventListener("handlePublishAsset", openPublishNow);
    return () => {
      window.removeEventListener("handlePublishAsset", openPublishNow);
    };
  }, []);

  useEffect(() => {
    const openPublishNow = () => {
      setOpenMintApp(false);
      refCaptable?.contentWindow?.postMessage(
        {
          data: { success: true },
          type: "mintAssetStatus",
          key: "liquidity",
        },
        "*"
      );
    };
    window.addEventListener("closeMintApp", openPublishNow);
    return () => {
      window.removeEventListener("closeMintApp", openPublishNow);
    };
  }, [refCaptable]);
 
  const isShowLoginAppPopUp = () => {
    switch (true) {
      case isMLC:
        return false;
      case !isLoggedIn && window.location.pathname === "/login":
        return true;
      default:
        return isLoggedIn;
    }
  };

  return (
    //Managing All the Routes and notification container
    <ErrorBoundary fallback={<ErrorPage />}>
      <>
        {showEmailNotification && <EmailVerifyNotification />}

        {userDetails?.signDocStatus === "processing" ? (
          <AccountApprovalBanner />
        ) : (
          userDetails?.id && (
            <>
              {!userDetails?.onboardingData?.isOnboardingComplete ? (
                <VerificationSteps />
              ) : (
                <>{accountVerify && <AccountApprovalBanner approve={true} />}</>
              )}
            </>
          )
        )}
        {!allowedDesktopView && isShowLoginAppPopUp() && isMobileScreen ? (
          <div className={styles.loginAppPopupModal__wrapper}>
            <MobileComponent />
          </div>
        ) : (
          <>
            {editPrivate && <PrivateModal />}
            {!hideKycSignUpModal && reOpenKycSignupModal && <KycSignupModal />}
            {OpenKycReviewModal && (
              <ReactModal
                visible={true}
                onClose={() => setOpenKycReviewModal(false)}
                closeBtn={true}
                outerClassName={classNames(styles.KYCReviewModal)}
              >
                <KycReview handleClose={() => setOpenKycReviewModal(false)} />
              </ReactModal>
            )}
            {kybModalVisible && <UpgradeKyb />}
            {isUserLoginPopupVisible &&
              (isMobileScreen && !isMLC ? (
                <div className={styles.loginAppPopupModal__wrapper}>
                  <i
                    className={`ri-close-line ${styles.loginAppPopupModal__crossIcon}`}
                    onClick={() => setUserLoginPopupVisible(false)}
                  ></i>
                  <MobileComponent />
                </div>
              ) : (
                <>
                  <NewModal
                    isOpen={isUserLoginPopupVisible}
                    modalName={"User Login Modal "}
                    title=""
                    isStopOutsideClick={false}
                    closeOnEscBtn={false}
                    showCloseBtn={true}
                    className="social-pop-up"
                  >
                    <LoginPopUpModal
                      closeModal={() => setUserLoginPopupVisible(false)}
                    />
                  </NewModal>
                </>
              ))}

            {kybModalVisible && <UpgradeKyb />}
            {openPublishNowReview && (
              <ReactModal
                visible={true}
                onClose={() => setOpenPublishNowReview(false)}
                closeBtn={true}
                maskClosable={false}
                outerClassName={classNames(styles.KYCReviewModal, {
                  [styles.publishNowModalHide]: openMintApp,
                })}
              >
                <PublishNowReview
                  handleClose={() => setOpenPublishNowReview(false)}
                />
              </ReactModal>
            )}
            {openMintApp && (
              <ReactModal
                visible={true}
                onClose={() => {
                  setOpenMintApp(false);
                }}
                closeBtn={true}
                outerClassName={classNames(styles.KYCReviewModal)}
                maskClosable={false}
              >
                <MintApp
                  setOpenMintApp={setOpenMintApp}
                  openMintApp={"true"}
                  dark="false"
                />
              </ReactModal>
            )}
            {showUserEditModal && <EditUserDetailsModal />}
            {isFormVisible && <Hubspot />}
            <AllRoutes />
            <ToastContainer
              position="top-center"
              autoClose={2000}
              hideProgressBar
              closeOnClick
              pauseOnHover
              transition={Slide}
              theme={rendertheme}
              limit={1}
            />
          </>
        )}
      </>
    </ErrorBoundary>
  );
};

export default App;
