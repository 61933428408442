import React, { useEffect, useRef } from "react";

import { Json } from "../../types";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import { MORNING_LINE } from "@views/exchange/constants";

interface IVariablePieChart {
  chartData: Json[],
  colors: string[][],
  name: string
}

export const VariablePieChart = ({ name, chartData, colors = [] }: IVariablePieChart) => {
  const animationRef = useRef(true);
  const { config } = usePermissionContext();
  const isMLC = config?.name === MORNING_LINE;

  useEffect(() => {
    const highCharts = (window as any).Highcharts;
    if (!highCharts) {
      console.error('Highcharts is not loaded yet.');
      return;
    }
  
    const variablePieScript = document.createElement('script');
    variablePieScript.src = 'https://cdnjs.cloudflare.com/ajax/libs/highcharts/11.4.8/modules/variable-pie.min.js';
  
    variablePieScript.onload = () => {
      // Proceed with chart initialization
      try {
        highCharts.setOptions({
          colors: colors.map(function (color) {
            return {
              radialGradient: {
                cx: 0.5,
                cy: 0.5,
                r: 0.5
              },
              stops: [
                [0, highCharts.color(color?.[0] || "").brighten(-0.1).get('rgb')],
                [1, color?.[1] || ""]
              ]
            };
          })
        });
  
        let chart = highCharts.chart('variable-pie-chart', {
          chart: {
            type: 'variablepie',
            backgroundColor: 'transparent',
            zooming: { mouseWheel: { enabled: false } },
            animation: false,
          },
          title: { text: '' },
          exporting: { enabled: false },
          credits: { enabled: false },
          plotOptions: {
            variablepie: {
              cursor: 'pointer',
              borderWidth: 0,
              dataLabels: { enabled: false },
            },
            series: {
              animation: animationRef?.current
            }
          },
          tooltip: {
            headerFormat: '',
            pointFormat: `<span class="pie-template">
              <span style="color:{point.color}">\u25CF</span> <b class="value"> {point.name}</b><br/>
              Current: <b class="value">{point.current}</b><br/>
              P&L: <b class="value">{point.gainLoss}</b>
              <span style="color: {point.gainLossPercentageColor}">({point.gainLossPercentage}%)</span>
            </span>`
          },
          series: [{
            minPointSize: 10,
            innerSize: '20%',
            zMin: 0,
            name: name || '',
            borderRadius: 0,
            data: chartData,
          }]
        });
      } catch (err: any) {
        console.log(err);
      }
    };

    document.head.appendChild(variablePieScript);
    setTimeout(() => {
      if (animationRef?.current) animationRef.current = false;
    }, 500)
  
    return () => {
      const script = document.querySelector(
        'script[src="https://cdnjs.cloudflare.com/ajax/libs/highcharts/11.4.8/modules/variable-pie.min.js"]'
      );
      if (script) {
        document.head.removeChild(script);
      }
    };
  }, [chartData, colors, name]);
  
  return (
    <div className={isMLC ?"mlc_variable-pie-chart-container" : "variable-pie-chart-container"} id="variable-pie-chart" />
  );
};
