import React, { FC } from "react";
import "./bank-details-card.scss";
import { useNavigate } from "react-router-dom";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import { MORNING_LINE } from "@views/exchange/constants";

type IbankDetailsCard = {
  icon: string;
  header: string;
  details: string;
  url: string;
};

export const BankDetailsCard: FC<IbankDetailsCard> = (props) => {
  const { icon, header, details, url } = props;
  const navigate = useNavigate();
  const { config } = usePermissionContext();
  const isMLC = config?.name === MORNING_LINE;

  const redirect = (url: string) => {
    navigate(url);
  };

  return (
    <div className={isMLC ? "mlc_bank-detail-card" : "bank-detail-card"} onClick={() => redirect(url)}>
      <img src={icon} alt={icon} />
      <div className="bank-detail-card-info">
        <p className="info-header">{header}</p>
        <p className="info-description">{details}</p>
      </div>
    </div>
  );
};
