import React, { useEffect, useState } from "react";
import BarChart from "@components/Charts/BarCharts";
import { SeriesConfig } from "./type";
import { transformApiResponseToChartData } from "./chart-utils";

import "./reports-charts.scss";

interface IReportsChart {
  title?: string
  response: any
  seriestype: any
  chartType: "profit" | "portfolio"
  dataKey?: string
  loading?: boolean
}

interface ICustomHeader {
  title: string
}

const CustomHeaderProfitLoss: React.FC<ICustomHeader> = ({ title }) => {
  return (
    <div className="chart-header-container">
      <h1>{title}</h1>
      <div className="header-subtitle flex-row">
        {/* <p>Cost Basis : $179,363</p> */}
        {/* <div className="header-profit-loss">
          <p>P&L : $179,363</p>
          <span className="percentage negative">2.67%</span>
        </div> */}
      </div>
    </div>
  );
};

const CustomHeaderPortfolio: React.FC<ICustomHeader> = ({ title }) => {
  return (
    <div className="chart-header-container">
      <h1>{title}</h1>
      {/* <div className="header-subtitle flex-column">
        <p>Cost Basis : $179,363</p>
        <p>Current value : $1,00,363</p>
      </div> */}
    </div>
  );
};

export const ReportsChart: React.FC<IReportsChart> = ({title = "Chart", response = [], seriestype = [], chartType = "", dataKey = "", loading = false}) => {
  const [categoriesData, setcategoriesData] = useState<string[]>([])
  const [seriesData, setSeriesData] = useState<SeriesConfig[]>([])
 
  useEffect(() => {
    const {data} = response ?? {};
    if(data){
       const { categories, series } = transformApiResponseToChartData(
          response,
          seriestype,
          dataKey
        );
         
        setcategoriesData(categories);
        setSeriesData(series as [])
      }
  
   
  }, [response, dataKey, seriestype]) 
  
  return (
    <div className="report-chart-container">
      <BarChart
        title={title}
        categories={categoriesData}
        series={seriesData}
        customHeader={chartType === 'profit' ? <CustomHeaderProfitLoss title={title} /> : <CustomHeaderPortfolio title={title} />}
        height={280}
        isChartLoading={loading}
      />
    </div>
  );
};
