import { APIS } from 'constant'
import { useCookie, useLocalStorage, useNetwork } from 'hooks'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import { DisplayUserState, allCoOwnerState, coUserDetailsLoading, imageUplaodingState, userDropDownState, coOwnerApiCall } from 'states'
import styles from "./authorisedUser.module.sass"
import { Skeleton } from 'components'
import { SelectUserDropdown } from './components'
import { Json } from '../../../../types'
import { usePermissionContext } from '@hooks/authentication/permissionsContext'
import { MORNING_LINE } from '@views/exchange/constants'

interface User {
    name: string;
    image: string;
    id: string;
    isPrimary?: boolean;
    isActive?: boolean;
    ownerId: string;
}

export const ChangeUser = () => {
    const { get, data: invitedData, loading } = useNetwork()
    const [user, setUser] = useRecoilState(userDropDownState)
    const uploading = useRecoilValue(imageUplaodingState)
    const [coOwnerList, setCoOwnerList] = useRecoilState(allCoOwnerState)
    const [coUserDetailLoading, setCoUserDetailLoading] = useRecoilState(coUserDetailsLoading)
    const [displayUser, setDisplayUser] = useRecoilState<any>(DisplayUserState);
    const setcoOwnerApiCall = useSetRecoilState(coOwnerApiCall);
    const { get: getCookieData } = useCookie();
    const { config } = usePermissionContext();
    const isMLC = config?.name === MORNING_LINE;

      const cookieData = useMemo(() => {
        return getCookieData("userDetails");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); 
    

    const [coOwnerDropDownList, setCoOwnerDropDownList] = useState([]);

    const { set: setLocalStorage } = useLocalStorage();

    const coOwnerMappedData = useCallback((coOwnerData: Json) => {
        const dropDropValue: User[] = coOwnerData?.map((user: Json) => (
            {
                name: user?.name, image: user?.profileImage,
                id: user?.ownerId,
                type: user?.type,
                customerId: user?.customerId,
                ...(user?.isPrimary ? { isPrimary: user.isPrimary } : {}),
                ...(user?.isActive ? { isActive: user?.isActive } : {}),
                ownerId: user?.ownerId
            }
        ))
        const activeAccount = dropDropValue?.find((user) => user?.isActive === true);
        const myAccount = dropDropValue?.find((user) => user?.name === "My Account");
        const newDisplayUser = activeAccount ?? myAccount;
        const userDisplay = {
          ...(newDisplayUser ? {
              ...newDisplayUser,
              isPrimary: coOwnerData?.length ? !!newDisplayUser.isPrimary : true,
          } : {isPrimary: true})
      };
      
        setDisplayUser(userDisplay)
        setLocalStorage("dropdown", { isPrimary: coOwnerData?.length ? !!newDisplayUser?.isPrimary  : true});

        setCoOwnerDropDownList(dropDropValue as any);

    }, [])

    useEffect(() => {
      if(cookieData?.token){
        get(APIS.GETCOOWNER)
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cookieData?.token, uploading])

    useEffect(() => {
        setCoUserDetailLoading(true)
        if (invitedData) {
            coOwnerMappedData(invitedData?.data);
            setCoOwnerList(invitedData?.data)
            setCoUserDetailLoading(false)
            setcoOwnerApiCall(true);
        }
    }, [invitedData, setCoOwnerList, setCoUserDetailLoading, coOwnerMappedData])

    useEffect(() => {
        if (!displayUser) return;
        else setUser(displayUser);
    }, [displayUser, setUser]);


    return (
      <>
        {coOwnerList?.length ? (
          loading || uploading ? (
            <Skeleton height={40} width={195} />
          ) : (
            <div className={isMLC ? styles.mlc_userDropdown : styles.userDropdown}>
              <SelectUserDropdown
                className={styles.dropdown}
                value={user}
                setValue={() => setUser}
                users={coOwnerDropDownList}
              />
            </div>
          )
        ) : (
          ""
        )}
      </>
    );
}
