import React from "react";
import "./container.scss";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import { MORNING_LINE } from "@views/exchange/constants";

export const Container = ({ children }: any) => {
  const { config } = usePermissionContext();
    const isMLC = config?.name === MORNING_LINE;
  return <div className={isMLC? "mlc_container-body":"container-body"}>{children}</div>;
};
