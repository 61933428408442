import React, { useCallback, useRef } from "react";
import "./biometric-setup.scss";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import { MORNING_LINE } from "@views/exchange/constants";
import { throttle } from "@utils/throttle";

const BiometricSetup = ({ isDarkMode = false, handleWebAuthn ,handleLogin }: any) => {
  const { config } = usePermissionContext();
  const isMLC = config?.name === MORNING_LINE;
  
  const throttledHandleWebAuthn = useRef(throttle(handleWebAuthn, 5000)).current;

  const handleClick = useCallback(() => {
    throttledHandleWebAuthn();
  }, [throttledHandleWebAuthn]);

  return (
    <div
      className={
        isMLC ? "mlc_biometric-setup-wrapper" : `biometric-setup-wrapper`
      }
    >
      <div className={`biometric-setup-wrapper__inner`}>
        <div
          className={
            isMLC
              ? "mlc_biometric-setup-wrapper__left"
              : `biometric-setup-wrapper__left`
          }
        >
          <div>
            <div>
              <h2 className="touch-id-title">
                Login your account with Touch ID
              </h2>
              <p className="touch-id-description">
                Easily log in using your fingerprint for faster and more secure
                access.
              </p>
              <ul className="touch-id-list">
                <li className="touch-id-list-item">Quick, hassle free login</li>
                <li className="touch-id-list-item">Enhanced security</li>
                <li className="touch-id-list-item">Set up in seconds</li>
              </ul>
              {isMLC && (
                <div className={"mlc_touch-id-fingerprint"}>
                  <i className="ri-fingerprint-line"></i>
                </div>
              )}
              <div className="touch-id-button-group">
                <button
                  className="touch-id-button touch-id-add-btn"
                  onClick={handleClick}
                >
                  Add Biometric
                </button>
                <button className="touch-id-button touch-id-not-now-btn" onClick={handleLogin}>
                  Explore {config?.name}
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={
            isMLC
              ? "mlc_biometric-setup-wrapper__right"
              : `biometric-setup-wrapper__right`
          }
        >
          <div className="touch-id-fingerprint">
            <i className="ri-fingerprint-line"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BiometricSetup;
