import React from "react";
import styles from "./myDocumentsNavigation.module.sass";
import classNames from "classnames";

interface NavMyDocumentsNavigationProps {
  activeTab: string;
  onTabChange: (tab: string) => void;
}

const MyDocumentsNavigation: React.FC<NavMyDocumentsNavigationProps> = ({
  activeTab,
  onTabChange,
}) => {
  const tabs = ["Monthly Statements"];

  return (
    <div className={styles.myDocumentsNavigation}>
      {tabs.map((tab) => (
        <div
          key={tab}
          onClick={() => onTabChange(tab)}
          className={classNames(styles.myDocumentsNavigation__tab, {
            [styles.myDocumentsNavigation__tab__active]: activeTab === tab,
          })}
        >
          {tab}
        </div>
      ))}
    </div>
  );
};

export default MyDocumentsNavigation;
