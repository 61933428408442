import React, { useCallback, useEffect, useMemo, useState } from "react";
import "./wallet-balance.scss";
import { Button, ReactModal } from "components";
import { useCurrency, useNotification } from "hooks";
import { Withdraw } from "../withdraw";
import { Deposit } from "../deposit/deposit";
import { UseTransaction } from "views/MyWallet/store/hooks";
import { FortressAccountInfo} from "views/MyWallet/store/state";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  UsersLoadingState,
  userDetailsState,
  userPersonalDetails,
} from "@states/user";
import { useFullStoryTrackEvent } from "@hooks/useFullStoryTrack";
import {
  DepositModalOpenState,
  IsEnoghBalance,
  WireTransactionDetailsState,
  WithdrawModalOpenState,
} from "@states/wallet";
import classNames from "classnames";
import MpcIncomplete from "@views/exchange/components/Actions/Form/MpcIncomplete/MpcIncomplete";
import MpcSetupModal from "@views/exchange/components/Actions/Form/MpcSetupModal/MpcSetupModal";
import styles from "./walletBalance.module.sass"
import { ALLOW_MPC_ENV } from "../../../../envs";
import { KycReview } from "@views/exchange/components/compliance/kyc-review";
import { trackEventSG } from "libs";
import { usePermissionContext } from "@hooks/authentication/permissionsContext";
import { MORNING_LINE } from "@views/exchange/constants";
export const WalletBalance = () => {
  const [isWithdrawModalOpen, setIsWithdrawModalOpen] = useRecoilState(
    WithdrawModalOpenState
  );
  const [isDepositModalOpen, setIsDepositModalOpen] = useRecoilState<boolean>(
    DepositModalOpenState
  );
  const availableBalance = useRecoilValue<any>(FortressAccountInfo);
  const [mpcIncomplete, setMpcIncomplete] = useState(false);
  const [setupMpcWallet, setSetupMpcWallet] = useState(false);
  const [showKYCReview,setShowKYCReview]=useState(false);

  const { isTransactionEnabled, accounts ,signDocStatus} =
    useRecoilValue(userPersonalDetails);
  const profileRefresh = useRecoilValue(UsersLoadingState);
  const setWiteTransactionDetails = useSetRecoilState(
    WireTransactionDetailsState
  );
  const setIsBalance = useSetRecoilState(
    IsEnoghBalance
  );
  const userDetails = useRecoilValue(userDetailsState);

  const { kycStatus, kybStatus, amlStatus, accreditationStatus } =
  userDetails?.data?.onboardingData || {};

  const isAnyStatusPending = [kycStatus, kybStatus, amlStatus].some(
    (status) => status === "pending" || status === "processing" || status === "NA"
  );
  const { config } = usePermissionContext();
  const isMLC = config?.name === MORNING_LINE;

  const isAnyStatusRejected = [
    kycStatus,
    kybStatus,
    amlStatus,
  ].some((status) => status === "rejected");

  const { formatCurrencyWithBillion } = useCurrency();
  const { getFortressAccountInfo } = UseTransaction();
  const { errorNotification } = useNotification();
  const { trackEvent } = useFullStoryTrackEvent();

  useEffect(() => {
    getFortressAccountInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeModal = () => {
    setIsWithdrawModalOpen(false);
    setWiteTransactionDetails({});
    setIsBalance(false)
  };

  const closeDepositModal = () => {
    setIsDepositModalOpen(false);
    setIsBalance(false)
  };

  const renderBalance = useMemo(() => {
    return (
      <p className={isMLC ? "mlc_wallet-balance-main" : "wallet-balance-main"}>
        {formatCurrencyWithBillion(availableBalance?.balance, 2)}{" "}
      </p>
    );
  }, [availableBalance, formatCurrencyWithBillion]);

  const handleTransaction = useCallback(
    (modalType: string) => {
      const {id:userId} = userDetails?.data;
      if(modalType === "Deposit"){
        const eventData = { 
          timestamp: new Date(), 
          user_id: userId 
        };
        trackEventSG("deposit_button_clicked_web", eventData);

      }
      if(modalType === "Deposit" && signDocStatus === "processing"){
        setShowKYCReview(true)
        return;
      }
      if (isTransactionEnabled) {
        trackEvent("Wallet Click", {
          name: modalType,
        });
        if (isAnyStatusPending || isAnyStatusRejected || (!kybStatus && (accreditationStatus === "NA" || accreditationStatus === "pending"))) {
          setShowKYCReview(true)
          return;
        }
        if (ALLOW_MPC_ENV && !userDetails?.data?.isMPCWallet) {
          setMpcIncomplete(true);
          return;
        }
        if (modalType === "Withdraw") {
          setIsWithdrawModalOpen(true);
        } else if (modalType === "Deposit") {
          setIsDepositModalOpen(true);
        }
      } else {
        errorNotification(
          `You don't have access to ${modalType.toLowerCase()} transaction`
        );
      }
    },
    [accreditationStatus, errorNotification, isAnyStatusPending, isAnyStatusRejected, isTransactionEnabled, kybStatus, setIsDepositModalOpen, setIsWithdrawModalOpen, trackEvent]
  );

  const handleOpenMpcSetup = useCallback(() => {
    setSetupMpcWallet(true);
    setMpcIncomplete(false);
  }, []);

  const handleCancelMpcSetup = useCallback(() => {
    setSetupMpcWallet(false)
  }, []);


  return (
    <>
      <div className={isMLC? "mlc_wallet-balance-container":"wallet-balance-container"}>
        <div className={isMLC?"mlc_wallet-balance-cash-container":"wallet-balance-cash-container"}>
          <p className="wallet-balance-header">Cash balance</p>
          <div className="wallet-balance-body">{renderBalance}</div>
        </div>
        {!!(availableBalance?.deposit_processing || availableBalance?.withdrawal_processing) &&
          <div className={isMLC? "mlc_wallet-processing-balance" :"wallet-processing-balance"}>
            {!!availableBalance?.deposit_processing && <p>
              <span className="processing-label">Deposit processing</span>
              <span
                className="processing-amount">{formatCurrencyWithBillion(availableBalance?.deposit_processing, 2)}</span>
            </p>}
            {!!availableBalance?.withdrawal_processing && <p>
              <span className="processing-label">Withdraw processing</span>
              <span
                className="processing-amount">{formatCurrencyWithBillion(availableBalance?.withdrawal_processing, 2)}</span>
            </p>}
          </div>}
        <div className={isMLC? "mlc_wallet-balance-btn-container" :"wallet-balance-btn-container"}>
          <Button
            type="button__filled button__filled--secondary btn-withdraw"
            label="Withdraw"
            handleClick={() => handleTransaction("Withdraw")}
            disabled={profileRefresh}
          />
          <Button
            type="button__filled button__filled--primary"
            label="Deposit"
            handleClick={() => handleTransaction("Deposit")}
            disabled={profileRefresh}
          />
        </div>
      </div>
      <Withdraw openModal={isWithdrawModalOpen} onClose={closeModal} />
      <Deposit openModal={isDepositModalOpen} onClose={closeDepositModal} />
      <ReactModal
        visible={mpcIncomplete}
        onClose={() => {
          setMpcIncomplete(false)
        }}
        closeBtn={true}
        maskClosable={false}
        outerClassName={classNames(
          styles.confirmationModalOuter,
          styles.kycStatusModal
        )}
      >
        {mpcIncomplete && (
          <MpcIncomplete handleOpenMpcSetup={handleOpenMpcSetup} showZeroBalance={false} />
        )}
       </ReactModal>
       <ReactModal
        visible={setupMpcWallet}
        onClose={handleCancelMpcSetup}
        closeBtn={true}
        maskClosable={false}
        outerClassName={classNames(styles.MpcSetupStatusModal)}
      >
        <MpcSetupModal handleCancelMpcSetup={handleCancelMpcSetup} />
      </ReactModal>
      <ReactModal
        maskClosable={false}
        visible={showKYCReview}
        onClose={()=>setShowKYCReview(false)}
        closeBtn={true}
        outerClassName={classNames(styles.confirmationModalOuter, styles.KYCReviewModal)}
      >
       <KycReview
       handleClose={()=>setShowKYCReview(false)}
       />
      </ReactModal>
    </>
  );
};
